import './GiftPopup.css';
import { motion } from "framer-motion"
import TemplatePopup from '../TemplatePopup/TemplatePopup';
import { Coin } from '../../assets/icons/icons';
import gift from '../../assets/images/gift.webp'
import { useContext, useEffect, useState } from 'react';
import { TranslationContext } from '../../assets/contexts/translationContext';

function GiftPopup({ referral, isOpen, setOpen }) {

    const {
        giftPopup: {
            receiveGift,
            receiveGiftFrom,
            receiveBtn,
        },
    } = useContext(TranslationContext);

    const variants = {
        open: {
            // y: "0%",
            opacity: 1,
            transition: { duration: 0.5 },
        },
        closed: {
            // y: "100%",
            opacity: 0,
            transition: { duration: 0.5 },
            // transitionEnd: {
            //     display: "none",
            // },
        }
    };

    function handleClick() {
        window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft')
        setOpen(false)
    }

    return (
        <motion.div
            initial={false}
            animate={isOpen ? "open" : "closed"}
            variants={variants}
            className={`${isOpen ? 'gift-popup_visible' : 'gift-popup_unvisible'} gift-popup-box`}
        >
            {isOpen ? <img src={gift} alt='' className='gift-popup__bg' /> : null}
            <TemplatePopup secondClassName={'gift-popup'} isOpen={isOpen} setOpen={setOpen}>
                {referral?.parent?.user_data?.username ?
                    <p className='gift-popup__text_white'>{receiveGiftFrom}</p>
                    :
                    <p className='gift-popup__text_white'>{receiveGift}</p>
                }
                <p className='gift-popup__text_orange'>
                    {referral?.parent?.user_data?.username}
                </p>
                <p className='gift-popup__coin'>
                    <Coin mainClassName={'gift-popup__icon'} />
                    5000
                </p>
                <button onClick={() => handleClick()} className='gift-popup__btn'>{receiveBtn}</button>
            </TemplatePopup>
        </motion.div>
    );
}

export default GiftPopup