import { useCallback, useContext, useEffect, useState } from 'react';
import { Coin } from '../../assets/icons/icons';
import { AVATARS } from '../../assets/utils/utils';
import '../Profile/Profile.css';
import { motion } from "framer-motion"
import { CARDS_LIMIT, TASK_COUPON } from '../../assets/utils/constants';
import ProfileCard from './ProfileCard/ProfileCard';
import ProfilePopup from './ProfilePopup/ProfilePopup';
import ProfileInfo from './ProfileInfo/ProfileInfo';
import mainApi from '../../assets/api/MainApi';
import { useLocation } from 'react-router-dom';
import { TranslationContext } from '../../assets/contexts/translationContext';

function Profile({ total, personalData, setPersonalData, tabOpen, setTabOpen, user, setTotal, card, personalBonus, setPersonalBonus, language }) {

    const {
        profile: {
            links,
            addToWalletBtn,
            myRewardsTitle,
            epmtyRewardsList,
            archiveTitle,
            emptyArchive,
            personalDataTitle,
        },
    } = useContext(TranslationContext);

    const NAV_LINKS = links.map((item, i) => {
        return {
            ...item,
            id: i + 1,
        }
    })

    const tg = window.Telegram?.WebApp
    const location = useLocation()
    const [currentCategory, setCurrentCategory] = useState(1)
    const [current, setCurrent] = useState(1)
    const [isOpen, setOpen] = useState(false)
    const [rewards, setRewards] = useState([])
    const [archiv, setArchiv] = useState([])
    const [step, setSteps] = useState(0)

    const [preloaders, setPreloaders] = useState({
        rewards: true,
        archiv: true
    })

    useEffect(() => {
        if (!user) return
        setPreloaders((prevState) => ({
            ...prevState,
            rewards: true
        }))
        mainApi
            .getOwnAwards({ limit: CARDS_LIMIT })
            .then((res) => {
                setRewards(res.data)
                // setRewards(TASK_COUPON)
                console.log(res.data);
                setPreloaders((prevState) => ({
                    ...prevState,
                    archiv: true
                }))
                mainApi
                    .getOwnArchivAwards({ limit: CARDS_LIMIT })
                    .then((res) => {
                        setArchiv(res.data)
                        // setArchiv(TASK_COUPON)
                        console.log(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        setPreloaders((prevState) => ({
                            ...prevState,
                            archiv: !preloaders.archiv
                        }))
                    })
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setPreloaders((prevState) => ({
                    ...prevState,
                    rewards: !preloaders.rewards
                }))
            })

        // setRewards(TASK_COUPON)
        // setArchiv(TASK_COUPON)
        if (personalData) {
            setSteps(2)
        }
    }, [user])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            mainApi
                .getOwnAwards({ limit: CARDS_LIMIT })
                .then((res) => {
                    setRewards(res.data)
                    console.log(res.data);
                    mainApi
                        .getOwnArchivAwards({ limit: CARDS_LIMIT })
                        .then((res) => {
                            setArchiv(res.data)
                            console.log(res.data);
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => {
                            // setPreloaders(false)
                        })
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    // setPreloaders(false)
                })
        }, 3000000)

        return () => {
            clearTimeout(delayDebounceFn);
        }
    }, [archiv, rewards])

    useEffect(() => {
        if (tabOpen) {
            setCurrentCategory(3)
            setTabOpen(false)
        }
        else {
            setCurrentCategory(1)
        }
    }, [])

    // const APPLE_LINK = 'https://2020-loyalty-program-backend.cabatest.ru/get-file/public/apple_passes/668bcde62a2d94f3b714269a.pkpass';

    function handleCategoryClick(id) {
        tg?.HapticFeedback.impactOccurred('soft')
        setCurrentCategory(id)
    }

    function getMoreCards() {
        if (currentCategory === 1) {
            const last_id = rewards[rewards.length - 1]?.id
            mainApi
                .getOwnAwards({
                    limit: CARDS_LIMIT,
                    last_id: last_id,
                })
                .then((res) => {
                    console.log(res)
                    if (res.length > 0) {
                        setRewards((prevValue) => (prevValue.concat(res)));
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        else if (currentCategory === 2) {
            const last_id = archiv[archiv.length - 1]?.id
            mainApi
                .getOwnArchivAwards({
                    limit: CARDS_LIMIT,
                    last_id: last_id,
                })
                .then((res) => {
                    console.log(res)
                    if (res.length > 0) {
                        setArchiv((prevValue) => (prevValue.concat(res)))
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    const handleScroll = (event) => {
        if (window.innerHeight + window.pageYOffset >= document.documentElement.scrollHeight && location.pathname === '/profile' && currentCategory === 1 || currentCategory === 2) {
            getMoreCards()
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    useEffect(() => {
        setSteps(2)
    }, [currentCategory])


    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                // onScroll={handleScrollElement}
                className={`profile`}>
                <div className='profile__header'>
                    <p className='profile__level'>Level <span className='profile__level-number'>{user?.balance_data?.level}</span></p>
                    <div className='profile__img-box'>
                        <img src={AVATARS[user?.balance_data?.level - 1]} alt='' className='profile__img'></img>
                        <p className='profile__name'>{tg?.initDataUnsafe?.user?.username ? tg?.initDataUnsafe?.user?.username : user?.user_data?.username}</p>
                        <p className='profile_id'>{card?.card_number}</p>
                    </div>
                    <p className='profile__coin-box'>
                        <Coin mainClassName={'profile__coin'} />
                        {Intl.NumberFormat('en-US', {
                            notation: "compact",
                            maximumFractionDigits: 2
                        }).format(total?.initial + total?.click)}
                    </p>
                    {tg?.platform === 'ios' && card && card.cards?.apple_wallet?.file?.url ?
                        <div className='profile__btn' onClick={() => tg?.openLink(card.cards?.apple_wallet?.file?.url)}>
                            {addToWalletBtn}
                        </div>
                        :
                        null}
                </div>
                <div className='profile__nav-block'>
                    {NAV_LINKS.map((item, i) => {
                        return (
                            <p key={i} className={`profile__nav ${item.id === currentCategory ? 'profile__nav_active' : ''}`} onClick={() => handleCategoryClick(item.id)}>{item.nav_title}</p>
                        )
                    })}
                </div>

                {currentCategory === 1 ?
                    <>
                        <p className='profile__title'>{myRewardsTitle}</p>
                        {preloaders.rewards ?
                            // <MiniPreloader isBig={true} />
                            <motion.div
                                className='profile__grid'
                            >
                                {Array(4).fill('').map((item, i) => {
                                    return (
                                        <ProfileCard
                                            key={i}
                                            preloade={true}
                                            item={item}
                                            setCurrent={setCurrent}
                                            current={current}
                                            setOpen={setOpen}
                                            isOpen={isOpen}
                                            arhiv={false}
                                            language={language}
                                        />
                                    )
                                })}
                            </motion.div>
                            :
                            <>
                                {rewards.length > 0 ?
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.5 }}
                                        className='profile__grid'
                                    >
                                        {rewards?.map((item, i) => {
                                            return (
                                                <div key={i}>
                                                    <ProfileCard
                                                        item={item}
                                                        setCurrent={setCurrent}
                                                        current={current}
                                                        setOpen={setOpen}
                                                        isOpen={isOpen}
                                                        arhiv={false}
                                                        language={language}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </motion.div>
                                    :
                                    <motion.p
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        className='profile__title_empty'>
                                        {epmtyRewardsList}
                                    </motion.p>
                                }
                            </>}
                    </>
                    : currentCategory === 2 ?
                        <>
                            <p className='profile__title'>{archiveTitle}</p>
                            {preloaders.archiv ?
                                // <MiniPreloader isBig={true} />
                                <motion.div
                                    className='profile__grid'
                                >
                                    {Array(4).fill('').map((item, i) => {
                                        return (
                                            <ProfileCard
                                                key={i}
                                                preloade={true}
                                                item={item}
                                                setCurrent={setCurrent}
                                                current={current}
                                                setOpen={setOpen}
                                                isOpen={isOpen}
                                                arhiv={false}
                                                language={language}
                                            />
                                        )
                                    })}
                                </motion.div>
                                :
                                <>
                                    {archiv.length > 0 ?
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 0.5 }}
                                            className='profile__grid'
                                        >
                                            {archiv?.map((item, i) => {
                                                return (
                                                    <div key={i}>
                                                        <ProfileCard
                                                            item={item}
                                                            setOpen={setOpen}
                                                            isOpen={isOpen}
                                                            arhiv={true}
                                                            language={language}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </motion.div>
                                        : <motion.p
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            className='profile__title_empty'>
                                            {emptyArchive}
                                        </motion.p>
                                    }
                                </>}
                        </>
                        :
                        <>
                            <motion.p
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                className='profile__title'
                            >
                                {personalDataTitle}
                            </motion.p>
                            <ProfileInfo
                                setPersonalData={setPersonalData}
                                step={step}
                                setSteps={setSteps}
                                personalData={personalData}
                                setArchiv={setArchiv}
                                setRewards={setRewards}
                                setTotal={setTotal}
                                user={user}
                                total={total}
                                personalBonus={personalBonus}
                                setPersonalBonus={setPersonalBonus}
                                language={language}
                            />
                        </>
                }
                <div className='profile__blur'></div>
            </motion.div >
            <ProfilePopup
                isOpen={isOpen}
                setOpen={setOpen}
                data={rewards}
                current={current}
                language={language}
            />
        </>
    );
}

export default Profile;