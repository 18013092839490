import './Loading.css';
import load from '../../assets/images/load.webp';
import { motion } from "framer-motion"
import 'react-circular-progressbar/dist/styles.css';
import { useContext } from 'react';
import { TranslationContext } from '../../assets/contexts/translationContext';

function Loading({ success }) {

    const {
        loading: {
          text,
          error,
          developed,
        },
      } = useContext(TranslationContext);

    const draw = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i) => {
            const delay = 0.5;
            return {
                pathLength: 1,
                opacity: 1,
                transition: {
                    pathLength: { delay, type: "spring", duration: 6.0, bounce: 0 },
                    opacity: { delay, duration: 0.5 }
                }
            };
        }
    };

    return (
        <motion.div
            animate={{ opacity: success ? 0 : 1 }}
            initial={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 6.0 }}
            className='loading'>
            {/* <img src={star} alt='' className='loading__star-bg'></img> */}
            <img src={load} alt='' className='loading__bg'></img>
            {success ?
                <>
                    <motion.svg
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        initial="hidden"
                        animate="visible"
                    >
                        <motion.circle
                            cx="25"
                            cy="25"
                            r="20"
                            stroke="#FFB31F"
                            variants={draw}
                            custom={4}
                        />
                    </motion.svg>
                    <p className='loading__load-text'>{text}</p>
                </>
                : <>
                    <div className='loading__error'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M7.99613 16C6.90211 16 5.8726 15.7909 4.9076 15.3727C3.94259 14.9597 3.09111 14.384 2.35317 13.6457C1.62038 12.9074 1.04499 12.0555 0.626996 11.09C0.208999 10.1246 0 9.09455 0 8C0 6.90545 0.208999 5.87544 0.626996 4.90997C1.04499 3.9445 1.62038 3.09519 2.35317 2.36205C3.09111 1.62375 3.94001 1.0455 4.89985 0.627299C5.86486 0.2091 6.89437 0 7.98839 0C9.08757 0 10.1197 0.2091 11.0847 0.627299C12.0497 1.0455 12.9011 1.62375 13.6391 2.36205C14.377 3.09519 14.955 3.9445 15.373 4.90997C15.791 5.87544 16 6.90545 16 8C16 9.09455 15.791 10.1246 15.373 11.09C14.955 12.0555 14.377 12.9074 13.6391 13.6457C12.9011 14.384 12.0497 14.9597 11.0847 15.3727C10.1197 15.7909 9.09015 16 7.99613 16ZM8.00387 9.33979C8.46315 9.33979 8.69795 9.09971 8.70827 8.61955L8.83212 4.73185C8.84244 4.49435 8.76762 4.30074 8.60764 4.15102C8.44767 3.99613 8.24383 3.91868 7.99613 3.91868C7.74327 3.91868 7.53943 3.99613 7.38462 4.15102C7.2298 4.30074 7.15756 4.49177 7.16788 4.7241L7.27625 8.6273C7.29173 9.10229 7.53427 9.33979 8.00387 9.33979ZM8.00387 12.0039C8.26189 12.0039 8.48379 11.9213 8.66957 11.7561C8.85535 11.5908 8.94823 11.3792 8.94823 11.121C8.94823 10.868 8.85535 10.6563 8.66957 10.486C8.48379 10.3156 8.26189 10.2304 8.00387 10.2304C7.74069 10.2304 7.51621 10.3156 7.33043 10.486C7.14465 10.6563 7.05177 10.868 7.05177 11.121C7.05177 11.3792 7.14465 11.5908 7.33043 11.7561C7.52137 11.9213 7.74585 12.0039 8.00387 12.0039Z" fill="#FF614C" />
                        </svg>
                        <p className='loading__error-text'>
                            {error}
                        </p>
                    </div>
                </>}
            <p className='loading__title'>Union</p>
            <p className='loading__title loading__title_second'>Coin</p>

            <div className='loading__terms'>
                {developed}
            </div>

            <div className='loading__social-box'>
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                    <path d="M18 0C8.05867 0 0 8.05867 0 18C0 27.9413 8.05867 36 18 36C27.9413 36 36 27.9413 36 18C36 8.05867 27.9413 0 18 0ZM26.2427 11.448C26.1413 13.1013 23.0747 25.44 23.0747 25.44C23.0747 25.44 22.8907 26.1627 22.2347 26.1867C21.9947 26.1947 21.704 26.1467 21.3573 25.848C20.664 25.2533 19.0613 24.1147 17.5707 23.0853C17.12 23.464 16.2213 24.2427 15.2907 25.1707C13.9733 26.488 14.1413 25.1707 14.1413 25.1707L14.5467 20.8693L14.5573 20.8747C14.576 20.832 14.6053 20.7947 14.6053 20.7947C14.6053 20.7947 22.368 13.8933 22.5733 13.1707C22.5893 13.1147 22.528 13.088 22.448 13.112C21.9333 13.3013 12.9947 18.9467 12.008 19.568C11.9493 19.6053 11.7893 19.5813 11.7893 19.5813L7.45333 18.168C7.45333 18.168 6.936 17.9573 7.10133 17.48C7.136 17.3813 7.20533 17.2987 7.41067 17.1547C8.37067 16.4853 25.1947 10.4373 25.1947 10.4373C25.1947 10.4373 25.6693 10.2773 25.9493 10.384C26.0773 10.432 26.16 10.488 26.2293 10.688C26.2533 10.76 26.2693 10.9173 26.2667 11.072C26.2667 11.184 26.2507 11.2853 26.2427 11.448Z" fill="white" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 18C0 8.05872 8.05872 0 18 0C27.9413 0 36 8.05872 36 18C36 27.9413 27.9413 36 18 36C8.05872 36 0 27.9413 0 18ZM26.6773 25.3066C27.632 25.0507 28.384 24.2987 28.64 23.344C29.104 21.6133 29.104 18 29.104 18C29.104 18 29.104 14.3867 28.64 12.656C28.384 11.7013 27.632 10.9493 26.6773 10.6934C24.9466 10.2293 18.0026 10.2293 18.0026 10.2293C18.0026 10.2293 11.0587 10.2293 9.328 10.6934C8.37331 10.9493 7.62135 11.7013 7.36537 12.656C6.9013 14.3867 6.9013 18 6.9013 18C6.9013 18 6.9013 21.6133 7.36537 23.344C7.62135 24.2987 8.37331 25.0507 9.328 25.3066C11.0587 25.7707 18.0026 25.7707 18.0026 25.7707C18.0026 25.7707 24.9466 25.7707 26.6773 25.3066ZM21.5494 18L15.7786 21.3306V14.6693L21.5494 18Z" fill="white" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 18C0 8.05867 8.05867 0 18 0C27.9413 0 36 8.05867 36 18C36 27.9413 27.9413 36 18 36C8.05867 36 0 27.9413 0 18ZM25.2853 23.0373C25.4747 22.5493 25.6053 21.9893 25.6427 21.1707C25.68 20.3493 25.688 20.088 25.688 18C25.688 15.912 25.68 15.6507 25.6427 14.8293C25.6053 14.0107 25.4747 13.4507 25.2853 12.9627C25.088 12.456 24.824 12.0267 24.3973 11.6C23.968 11.1707 23.5413 10.9093 23.0347 10.712C22.5467 10.5227 21.9867 10.392 21.168 10.3547C20.3467 10.3173 20.0853 10.3093 17.9973 10.3093C15.9093 10.3093 15.648 10.3173 14.8267 10.3547C14.008 10.392 13.448 10.5227 12.96 10.712C12.4533 10.9093 12.024 11.1733 11.5973 11.6C11.168 12.0293 10.9067 12.456 10.7093 12.9627C10.52 13.4533 10.3893 14.0107 10.352 14.8293C10.3147 15.6507 10.3067 15.912 10.3067 18C10.3067 20.088 10.3147 20.3493 10.352 21.1707C10.3893 21.9893 10.52 22.5493 10.7093 23.0373C10.9067 23.544 11.1707 23.9733 11.5973 24.4C12.0267 24.8293 12.4533 25.0907 12.96 25.288C13.4507 25.4773 14.008 25.608 14.8267 25.6453C15.648 25.6827 15.9093 25.6907 17.9973 25.6907C20.0853 25.6907 20.3467 25.6827 21.168 25.6453C21.9867 25.608 22.5467 25.4773 23.0347 25.288C23.5413 25.0907 23.9707 24.8267 24.3973 24.4C24.8267 23.9707 25.088 23.544 25.2853 23.0373ZM22.1039 12.9735C21.5946 12.9735 21.1812 13.3868 21.1812 13.8962C21.1812 14.4055 21.5946 14.8188 22.1039 14.8188C22.6132 14.8188 23.0266 14.4055 23.0266 13.8962C23.0266 13.3868 22.6132 12.9735 22.1039 12.9735ZM18 15C16.344 15 15 16.344 15 18C15 19.656 16.344 21 18 21C19.656 21 21 19.656 21 18C21 16.344 19.656 15 18 15Z" fill="white" />
                </svg> */}
            </div>
        </motion.div>
    );
}

export default Loading