import '../LevelUpPopup/LevelUpPopup.css';
import TemplatePopup from '../TemplatePopup/TemplatePopup';
import { LevelUp } from '../../assets/icons/icons';
import { useContext } from 'react';
import { TranslationContext } from '../../assets/contexts/translationContext';

function LevelUpPopup({ isOpenLevelUp, setOpenLevelUp }) {
    const {
        levelUpPopup: {
            title,
            closeBtn,
        },
    } = useContext(TranslationContext);

    function handleClick() {
        window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft')
        setOpenLevelUp(!isOpenLevelUp)
    }

    return (
        <TemplatePopup secondClassName={'popup-level-up'} isOpen={isOpenLevelUp} setOpen={setOpenLevelUp}>
            <LevelUp />
            <p className='popup-level-up__text'>{title}</p>
            <button onClick={() => handleClick()} className='popup-level-up__btn'>{closeBtn}</button>
        </TemplatePopup>
    );
}


export default LevelUpPopup;