export const Coin = ({ mainClassName, strokeClassName }) => {
    return (
        <svg className={`coin ${mainClassName}`} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <g clipPath="url(#clip0_191_5453)">
                <rect width="28" height="28" rx="14" fill="url(#paint0_linear_191_5453)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14 3.1998C8.03537 3.1998 3.20005 8.03513 3.20005 13.9998C3.20005 19.9645 8.03537 24.7998 14 24.7998C19.9647 24.7998 24.8 19.9645 24.8 13.9998C24.8 8.03513 19.9647 3.1998 14 3.1998ZM2.80005 13.9998C2.80005 7.81422 7.81446 2.7998 14 2.7998C20.1856 2.7998 25.2001 7.81422 25.2001 13.9998C25.2001 20.1854 20.1856 25.1998 14 25.1998C7.81446 25.1998 2.80005 20.1854 2.80005 13.9998Z" fill="#FFDB95" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14 26.6C20.9588 26.6 26.6 20.9588 26.6 14C26.6 7.04121 20.9588 1.4 14 1.4C7.04121 1.4 1.4 7.04121 1.4 14C1.4 20.9588 7.04121 26.6 14 26.6ZM14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#FFDB95" />
                <g filter="url(#filter0_d_191_5453)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14 6C13.2268 6 12.6 6.63207 12.6 7.41176V10.2353C12.6 11.015 13.2268 11.6471 14 11.6471C14.7732 11.6471 15.4 11.015 15.4 10.2353V7.41176C15.4 6.63207 14.7732 6 14 6ZM10.4323 8.82353C10.5985 8.82353 10.7333 8.95946 10.7333 9.12713V14.9412C10.7333 16.7605 12.1959 18.2353 14 18.2353C15.8041 18.2353 17.2667 16.7605 17.2667 14.9412V9.12713C17.2667 8.95946 17.4015 8.82353 17.5677 8.82353H20.5785C20.8113 8.82353 21 9.01383 21 9.24858V14.9412C21 18.8397 17.866 22 14 22C10.134 22 7 18.8397 7 14.9412V9.24858C7 9.01383 7.18871 8.82353 7.42151 8.82353H10.4323Z" fill="#FFDB95" />
                </g>
            </g>
            <defs>
                <filter id="filter0_d_191_5453" x="7" y="6" width="14" height="16.6" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="0.6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.646826 0 0 0 0 0.474641 0 0 0 0 0.212777 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_191_5453" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_191_5453" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_191_5453" x1="4.5" y1="26.5" x2="22" y2="2.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D6A65D" />
                    <stop offset="1" stopColor="#F8BB45" />
                </linearGradient>
                <clipPath id="clip0_191_5453">
                    <rect width="28" height="28" rx="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export const StarRewards = ({ mainClassName, strokeClassName }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
            <path d="M17 0L21.0871 12.9129L34 17L21.0871 21.0871L17 34L12.9129 21.0871L0 17L12.9129 12.9129L17 0Z" fill="#FFDB95" />
            <path d="M17 0V34L12.9129 21.0871L0 17L12.9129 12.9129L17 0Z" fill="#FFB31F" />
            <path d="M17 34L12.9129 21.0871L0 17L17 16.9999V34Z" fill="#BD7D00" />
            <path d="M17 34L21.0871 21.0871L34 17L17 16.9999V34Z" fill="#E1B96D" />
            <path d="M24.8463 9.15381L25.1607 10.1471L26.154 10.4615L25.1607 10.7759L24.8463 11.7692L24.5319 10.7759L23.5386 10.4615L24.5319 10.1471L24.8463 9.15381Z" fill="#FFB31F" />
            <path d="M23.5386 24.8462L23.853 25.8395L24.8463 26.1539L23.853 26.4683L23.5386 27.4616L23.2243 26.4683L22.231 26.1539L23.2243 25.8395L23.5386 24.8462Z" fill="#FFB31F" />
            <path d="M7.84627 22.231L8.16066 23.2243L9.15396 23.5386L8.16066 23.853L7.84627 24.8463L7.53188 23.853L6.53857 23.5386L7.53188 23.2243L7.84627 22.231Z" fill="#FFB31F" />
            <path d="M10.4615 6.53857L10.7759 7.53188L11.7692 7.84627L10.7759 8.16066L10.4615 9.15396L10.1471 8.16066L9.15381 7.84627L10.1471 7.53188L10.4615 6.53857Z" fill="#FFB31F" />
            <path d="M21.5767 3.92285L21.7339 4.4195L22.2305 4.5767L21.7339 4.73389L21.5767 5.23054L21.4195 4.73389L20.9229 4.5767L21.4195 4.4195L21.5767 3.92285Z" fill="#FFB31F" />
            <path d="M7.19242 10.4614L7.34962 10.9581L7.84627 11.1153L7.34962 11.2725L7.19242 11.7691L7.03523 11.2725L6.53857 11.1153L7.03523 10.9581L7.19242 10.4614Z" fill="#FFB31F" />
            <path d="M25.5 20.9229L25.6572 21.4195L26.1539 21.5767L25.6572 21.7339L25.5 22.2305L25.3428 21.7339L24.8462 21.5767L25.3428 21.4195L25.5 20.9229Z" fill="#FFB31F" />
            <path d="M12.4229 26.1538L12.5801 26.6505L13.0767 26.8077L12.5801 26.9649L12.4229 27.4615L12.2657 26.9649L11.769 26.8077L12.2657 26.6505L12.4229 26.1538Z" fill="#FFB31F" />
        </svg>
    )
}
export const LevelUp = ({ }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
            <g clipPath="url(#clip0_29_807)">
                <rect width="68" height="68" rx="34" fill="url(#paint0_linear_29_807)" />
                <circle cx="34" cy="34" r="26" stroke="#FFDB95" strokeWidth="1.6" strokeLinecap="round" />
                <g filter="url(#filter0_d_29_807)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M34 64C50.5685 64 64 50.5685 64 34C64 17.4315 50.5685 4 34 4C17.4315 4 4 17.4315 4 34C4 50.5685 17.4315 64 34 64ZM34 68C52.7777 68 68 52.7777 68 34C68 15.2223 52.7777 0 34 0C15.2223 0 0 15.2223 0 34C0 52.7777 15.2223 68 34 68Z" fill="url(#paint1_linear_29_807)" />
                </g>
                <g filter="url(#filter1_d_29_807)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M35.5749 13.0624C34.6238 11.6459 32.6515 11.6459 31.7004 13.0624C29.1199 16.9055 27.7164 21.5059 27.6801 26.2321L23.5591 28.3116C21.3808 29.4108 20 31.6912 20 34.1894V35.9096V38.0914V40.6362C20 41.2387 20.4742 41.7271 21.0593 41.7271C21.6443 41.7271 22.1185 41.2387 22.1185 40.6362V38.0914H27.6793V39.2724C27.6793 40.7788 28.8226 42 30.2329 42H37.0424C38.4527 42 39.5959 40.7788 39.5959 39.2724V38.0914H45.8815V40.6362C45.8815 41.2387 46.3557 41.7271 46.9407 41.7271C47.5258 41.7271 48 41.2387 48 40.6362V38.0914V35.9096V34.1894C48 31.6912 46.6192 29.4108 44.4409 28.3116L40.0624 26.1021C39.9108 26.0256 39.7502 25.9898 39.5922 25.9906C39.5107 21.3493 38.1114 16.84 35.5749 13.0624Z" fill="#FFDB95" />
                </g>
                <path fillRule="evenodd" clipRule="evenodd" d="M34 12.0292C33.1561 11.8923 32.2548 12.2367 31.7004 13.0624C29.1199 16.9055 27.7164 21.5059 27.6801 26.2321L23.5591 28.3116C21.3808 29.4108 20 31.6912 20 34.1894V35.9096V38.0914V40.6362C20 41.2387 20.4742 41.7271 21.0593 41.7271C21.6443 41.7271 22.1185 41.2387 22.1185 40.6362V38.0914H27.6793V39.2724C27.6793 40.7788 28.8226 42 30.2329 42H34V12.0292Z" fill="#FFC24B" />
                <path d="M34 47L34 59" stroke="#FFDB95" strokeWidth="1.6" strokeLinecap="round" />
                <path d="M28 47L28 55" stroke="#FFDB95" strokeWidth="1.6" strokeLinecap="round" />
                <path d="M40 47L40 55" stroke="#FFDB95" strokeWidth="1.6" strokeLinecap="round" />
                <path d="M21 17L21.7212 19.2788L24 20L21.7212 20.7212L21 23L20.2788 20.7212L18 20L20.2788 19.2788L21 17Z" fill="#FFDB95" />
                <path d="M21 17L21.7212 19.2788L24 20L21.7212 20.7212L21 23L20.2788 20.7212L18 20L20.2788 19.2788L21 17Z" fill="#FFDB95" />
                <path d="M21 17V23L20.2788 20.7212L18 20L20.2788 19.2788L21 17Z" fill="#FFB31F" />
                <path d="M21 23L20.2788 20.7212L18 20L21 20V23Z" fill="#BD7D00" />
                <path d="M21 23L21.7212 20.7212L24 20L21 20V23Z" fill="#E1B96D" />
                <path d="M46 22L46.4808 23.5192L48 24L46.4808 24.4808L46 26L45.5192 24.4808L44 24L45.5192 23.5192L46 22Z" fill="#FFDB95" />
                <path d="M46 22L46.4808 23.5192L48 24L46.4808 24.4808L46 26L45.5192 24.4808L44 24L45.5192 23.5192L46 22Z" fill="#FFDB95" />
                <path d="M46 22V26L45.5192 24.4808L44 24L45.5192 23.5192L46 22Z" fill="#FFB31F" />
                <path d="M46 26L45.5192 24.4808L44 24L46 24V26Z" fill="#BD7D00" />
                <path d="M46 26L46.4808 24.4808L48 24L46 24V26Z" fill="#E1B96D" />
            </g>
            <defs>
                <filter id="filter0_d_29_807" x="0" y="0" width="68" height="69" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.646826 0 0 0 0 0.474641 0 0 0 0 0.212777 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_29_807" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_29_807" result="shape" />
                </filter>
                <filter id="filter1_d_29_807" x="20" y="12" width="28" height="30.6" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="0.6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.646826 0 0 0 0 0.474641 0 0 0 0 0.212777 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_29_807" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_29_807" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_29_807" x1="34" y1="2.5" x2="34" y2="68" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C5882E" />
                    <stop offset="1" stopColor="#F8BB45" />
                </linearGradient>
                <linearGradient id="paint1_linear_29_807" x1="34" y1="0" x2="34" y2="68" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFDB95" />
                    <stop offset="0.505" stopColor="#FFF0D3" />
                    <stop offset="1" stopColor="#BA7B01" />
                </linearGradient>
                <clipPath id="clip0_29_807">
                    <rect width="68" height="68" rx="34" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export const CoinGrey = ({ mainClassName, strokeClassName }) => {
    return (
        <svg className={mainClassName} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <g clipPath="url(#clip0_31_1849)">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.99986 1.13379C2.86469 1.13379 1.13379 2.86469 1.13379 4.99986C1.13379 7.13503 2.86469 8.86593 4.99986 8.86593C7.13503 8.86593 8.86593 7.13503 8.86593 4.99986C8.86593 2.86469 7.13503 1.13379 4.99986 1.13379ZM1.00879 4.99986C1.00879 2.79565 2.79565 1.00879 4.99986 1.00879C7.20407 1.00879 8.99093 2.79565 8.99093 4.99986C8.99093 7.20407 7.20407 8.99093 4.99986 8.99093C2.79565 8.99093 1.00879 7.20407 1.00879 4.99986Z" className={strokeClassName} fill="#8B8D9B" />
                <path fillRule="evenodd" clipRule="evenodd" d="M5 9.5C7.48528 9.5 9.5 7.48528 9.5 5C9.5 2.51472 7.48528 0.5 5 0.5C2.51472 0.5 0.5 2.51472 0.5 5C0.5 7.48528 2.51472 9.5 5 9.5ZM5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z" className={strokeClassName} fill="#8B8D9B" />
                <path fillRule="evenodd" clipRule="evenodd" d="M5 2.14307C4.72386 2.14307 4.5 2.36881 4.5 2.64727V3.65567C4.5 3.93413 4.72386 4.15987 5 4.15987C5.27614 4.15987 5.5 3.93413 5.5 3.65567V2.64727C5.5 2.36881 5.27614 2.14307 5 2.14307ZM3.72581 3.15147C3.78519 3.15147 3.83333 3.20002 3.83333 3.2599V5.33634C3.83333 5.98609 4.35567 6.51281 5 6.51281C5.64433 6.51281 6.16667 5.98609 6.16667 5.33634V3.2599C6.16667 3.20002 6.21481 3.15147 6.27419 3.15147H7.34946C7.4326 3.15147 7.5 3.21943 7.5 3.30327V5.33634C7.5 6.72866 6.38071 7.85735 5 7.85735C3.61929 7.85735 2.5 6.72866 2.5 5.33634V3.30327C2.5 3.21943 2.5674 3.15147 2.65054 3.15147H3.72581Z" className={strokeClassName} fill="#8B8D9B" />
            </g>
            <defs>
                <clipPath id="clip0_31_1849">
                    <rect width="10" height="10" rx="5" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export const CoinWhite = ({ mainClassName }) => {
    return (
        <svg className={mainClassName} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <g clipPath="url(#clip0_123_526)">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.0001 1.5876C4.01086 1.5876 1.5876 4.01086 1.5876 7.0001C1.5876 9.98934 4.01086 12.4126 7.0001 12.4126C9.98934 12.4126 12.4126 9.98934 12.4126 7.0001C12.4126 4.01086 9.98934 1.5876 7.0001 1.5876ZM1.4126 7.0001C1.4126 3.91421 3.91421 1.4126 7.0001 1.4126C10.086 1.4126 12.5876 3.91421 12.5876 7.0001C12.5876 10.086 10.086 12.5876 7.0001 12.5876C3.91421 12.5876 1.4126 10.086 1.4126 7.0001Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M7 13.3C10.4794 13.3 13.3 10.4794 13.3 7C13.3 3.52061 10.4794 0.7 7 0.7C3.52061 0.7 0.7 3.52061 0.7 7C0.7 10.4794 3.52061 13.3 7 13.3ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M7 3C6.6134 3 6.3 3.31603 6.3 3.70588V5.11765C6.3 5.5075 6.6134 5.82353 7 5.82353C7.3866 5.82353 7.7 5.5075 7.7 5.11765V3.70588C7.7 3.31603 7.3866 3 7 3ZM5.21613 4.41176C5.29927 4.41176 5.36667 4.47973 5.36667 4.56357V7.47059C5.36667 8.38023 6.09793 9.11765 7 9.11765C7.90207 9.11765 8.63333 8.38023 8.63333 7.47059V4.56357C8.63333 4.47973 8.70073 4.41176 8.78387 4.41176H10.2892C10.4056 4.41176 10.5 4.50691 10.5 4.62429V7.47059C10.5 9.41983 8.933 11 7 11C5.067 11 3.5 9.41983 3.5 7.47059V4.62429C3.5 4.50691 3.59436 4.41176 3.71075 4.41176H5.21613Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_123_526">
                    <rect width="14" height="14" rx="7" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export const Start = ({ mainClassName, strokeClassName, secondStrokeClassName }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="44" viewBox="0 0 50 44" fill="none">
            <path d="M23.6949 3.23409C24.0951 2.6553 24.9049 2.6553 25.3051 3.23409C27.1863 5.95436 28.2 9.22116 28.2 12.575V21.9997C28.2 22.6904 27.6674 23.2 27.0714 23.2H21.9286C21.3326 23.2 20.8 22.6904 20.8 21.9997V12.575C20.8 9.22115 21.8137 5.95436 23.6949 3.23409Z" className={secondStrokeClassName} stroke="#777786" strokeWidth="1.6" />
            <path d="M20.5469 13.0601L17.24 14.6804C15.869 15.3521 15 16.7457 15 18.2723V23" className={secondStrokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M29 13.0601L32.3069 14.6804C33.6778 15.3521 34.5469 16.7457 34.5469 18.2723V23" className={secondStrokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M15 20.3335H20" className={secondStrokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="square" />
            <path d="M34.5469 20.3335H29.5469" className={secondStrokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="square" />
            <path d="M32.3584 2.86936C32.4135 2.74732 32.5868 2.74732 32.6419 2.86936L33.3229 4.37719C33.3385 4.41171 33.3661 4.43935 33.4006 4.45493L34.9085 5.13594C35.0305 5.19106 35.0305 5.36436 34.9085 5.41948L33.4006 6.10049C33.3661 6.11607 33.3385 6.14371 33.3229 6.17822L32.6419 7.68606C32.5868 7.8081 32.4135 7.8081 32.3584 7.68606L31.6773 6.17822C31.6618 6.14371 31.6341 6.11607 31.5996 6.10049L30.0918 5.41948C29.9697 5.36436 29.9697 5.19106 30.0918 5.13594L31.5996 4.45493C31.6341 4.43935 31.6618 4.41171 31.6773 4.37719L32.3584 2.86936Z" className={secondStrokeClassName} fill="#777786" />
            <path d="M25 30L25 42" className={strokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M19 30L19 38" className={strokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M31 30L31 38" className={strokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
        </svg>
    )
}

export const Menu = ({ mainClassName, strokeClassName, secondStrokeClassName }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="44" viewBox="0 0 50 44" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M34.1998 9.6H30.7331C29.8666 9.6 29.313 9.60124 28.893 9.63556C28.4917 9.66835 28.3552 9.72318 28.2975 9.75259C28.0341 9.88681 27.8199 10.101 27.6857 10.3644C27.6563 10.4221 27.6015 10.5586 27.5687 10.9599C27.5343 11.3799 27.5331 11.9335 27.5331 12.8V18.1333C27.5331 18.9998 27.5343 19.5534 27.5687 19.9734C27.6015 20.3747 27.6563 20.5112 27.6857 20.5689C27.8199 20.8323 28.0341 21.0465 28.2975 21.1807C28.3552 21.2102 28.4917 21.265 28.893 21.2978C29.313 21.3321 29.8666 21.3333 30.7331 21.3333H34.1998C35.0663 21.3333 35.6199 21.3321 36.0399 21.2978C36.4412 21.265 36.5776 21.2102 36.6354 21.1807C36.8988 21.0465 37.113 20.8323 37.2472 20.5689C37.2766 20.5112 37.3314 20.3747 37.3642 19.9734C37.3985 19.5534 37.3998 18.9998 37.3998 18.1333V12.8C37.3998 11.9335 37.3985 11.3799 37.3642 10.9599C37.3314 10.5586 37.2766 10.4221 37.2472 10.3644C37.113 10.101 36.8988 9.88681 36.6354 9.75259C36.5776 9.72318 36.4412 9.66835 36.0399 9.63556C35.6199 9.60124 35.0663 9.6 34.1998 9.6ZM26.2601 9.63803C25.9331 10.2798 25.9331 11.1198 25.9331 12.8V18.1333C25.9331 19.8135 25.9331 20.6536 26.2601 21.2953C26.5477 21.8598 27.0066 22.3187 27.5711 22.6064C28.2129 22.9333 29.0529 22.9333 30.7331 22.9333H34.1998C35.8799 22.9333 36.72 22.9333 37.3617 22.6064C37.9262 22.3187 38.3852 21.8598 38.6728 21.2953C38.9998 20.6536 38.9998 19.8135 38.9998 18.1333V12.8C38.9998 11.1198 38.9998 10.2798 38.6728 9.63803C38.3852 9.07354 37.9262 8.6146 37.3617 8.32698C36.72 8 35.8799 8 34.1998 8H30.7331C29.0529 8 28.2129 8 27.5711 8.32698C27.0066 8.6146 26.5477 9.07354 26.2601 9.63803Z" className={secondStrokeClassName} fill="#777786" />
            <path fillRule="evenodd" clipRule="evenodd" d="M32.4667 12.7998C32.9086 12.7998 33.2667 13.158 33.2667 13.5998V17.3331C33.2667 17.775 32.9086 18.1331 32.4667 18.1331C32.0249 18.1331 31.6667 17.775 31.6667 17.3331V13.5998C31.6667 13.158 32.0249 12.7998 32.4667 12.7998Z" className={strokeClassName} fill="#777786" />
            <path fillRule="evenodd" clipRule="evenodd" d="M29.8 30.4002C29.8 29.9584 30.1582 29.6002 30.6 29.6002L34.3334 29.6002C34.7752 29.6002 35.1334 29.9584 35.1334 30.4002C35.1334 30.842 34.7752 31.2002 34.3334 31.2002L30.6 31.2002C30.1582 31.2002 29.8 30.842 29.8 30.4002Z" className={strokeClassName} fill="#777786" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.8667 20.1336C14.8667 19.6918 15.2249 19.3336 15.6667 19.3336L19.4 19.3336C19.8419 19.3336 20.2 19.6918 20.2 20.1336C20.2 20.5754 19.8419 20.9336 19.4 20.9336L15.6667 20.9336C15.2249 20.9336 14.8667 20.5754 14.8667 20.1336Z" className={strokeClassName} fill="#777786" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.8667 23.8665C14.8667 23.4247 15.2249 23.0665 15.6667 23.0665L19.4 23.0665C19.8419 23.0665 20.2 23.4247 20.2 23.8665C20.2 24.3083 19.8419 24.6665 19.4 24.6665L15.6667 24.6665C15.2249 24.6665 14.8667 24.3083 14.8667 23.8665Z" className={strokeClassName} fill="#777786" />
            <path fillRule="evenodd" clipRule="evenodd" d="M34.1998 26.3998H30.7331C29.8666 26.3998 29.313 26.401 28.893 26.4354C28.4917 26.4682 28.3552 26.523 28.2975 26.5524C28.0341 26.6866 27.8199 26.9008 27.6857 27.1642C27.6563 27.2219 27.6015 27.3584 27.5687 27.7597C27.5343 28.1797 27.5331 28.7333 27.5331 29.5998V31.1998C27.5331 32.0663 27.5343 32.6199 27.5687 33.0399C27.6015 33.4412 27.6563 33.5777 27.6857 33.6354C27.8199 33.8988 28.0341 34.113 28.2975 34.2472C28.3552 34.2766 28.4917 34.3315 28.893 34.3642C29.313 34.3986 29.8666 34.3998 30.7331 34.3998H34.1998C35.0663 34.3998 35.6199 34.3986 36.0399 34.3642C36.4412 34.3315 36.5776 34.2766 36.6354 34.2472C36.8988 34.113 37.113 33.8988 37.2472 33.6354C37.2766 33.5777 37.3314 33.4412 37.3642 33.0399C37.3985 32.6199 37.3998 32.0663 37.3998 31.1998V29.5998C37.3998 28.7333 37.3985 28.1797 37.3642 27.7597C37.3314 27.3584 37.2766 27.2219 37.2472 27.1642C37.113 26.9008 36.8988 26.6866 36.6354 26.5524C36.5776 26.523 36.4412 26.4682 36.0399 26.4354C35.6199 26.401 35.0663 26.3998 34.1998 26.3998ZM26.2601 26.4378C25.9331 27.0796 25.9331 27.9196 25.9331 29.5998V31.1998C25.9331 32.88 25.9331 33.72 26.2601 34.3618C26.5477 34.9263 27.0066 35.3852 27.5711 35.6728C28.2129 35.9998 29.0529 35.9998 30.7331 35.9998H34.1998C35.8799 35.9998 36.72 35.9998 37.3617 35.6728C37.9262 35.3852 38.3852 34.9263 38.6728 34.3618C38.9998 33.72 38.9998 32.88 38.9998 31.1998V29.5998C38.9998 27.9196 38.9998 27.0796 38.6728 26.4378C38.3852 25.8733 37.9262 25.4144 37.3617 25.1268C36.72 24.7998 35.8799 24.7998 34.1998 24.7998H30.7331C29.0529 24.7998 28.2129 24.7998 27.5711 25.1268C27.0066 25.4144 26.5477 25.8733 26.2601 26.4378Z" className={secondStrokeClassName} fill="#777786" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.2667 9.6H15.8C14.9335 9.6 14.3799 9.60124 13.9599 9.63556C13.5586 9.66835 13.4221 9.72318 13.3644 9.75259C13.101 9.88681 12.8868 10.101 12.7526 10.3644C12.7232 10.4221 12.6683 10.5586 12.6356 10.9599C12.6012 11.3799 12.6 11.9335 12.6 12.8V31.2C12.6 32.0665 12.6012 32.6201 12.6356 33.0401C12.6683 33.4414 12.7232 33.5779 12.7526 33.6356C12.8868 33.899 13.101 34.1132 13.3644 34.2474C13.4221 34.2768 13.5586 34.3317 13.9599 34.3644C14.3799 34.3988 14.9335 34.4 15.8 34.4H19.2667C20.1331 34.4 20.6868 34.3988 21.1068 34.3644C21.5081 34.3317 21.6445 34.2768 21.7023 34.2474C21.9657 34.1132 22.1799 33.899 22.3141 33.6356C22.3435 33.5779 22.3983 33.4414 22.4311 33.0401C22.4654 32.6201 22.4667 32.0665 22.4667 31.2V12.8C22.4667 11.9335 22.4654 11.3799 22.4311 10.9599C22.3983 10.5586 22.3435 10.4221 22.3141 10.3644C22.1799 10.101 21.9657 9.88681 21.7023 9.75259C21.6445 9.72318 21.5081 9.66835 21.1068 9.63556C20.6868 9.60124 20.1332 9.6 19.2667 9.6ZM11.327 9.63803C11 10.2798 11 11.1198 11 12.8V31.2C11 32.8802 11 33.7202 11.327 34.362C11.6146 34.9265 12.0735 35.3854 12.638 35.673C13.2798 36 14.1198 36 15.8 36H19.2667C20.9468 36 21.7869 36 22.4286 35.673C22.9931 35.3854 23.4521 34.9265 23.7397 34.362C24.0667 33.7202 24.0667 32.8802 24.0667 31.2V12.8C24.0667 11.1198 24.0667 10.2798 23.7397 9.63803C23.4521 9.07354 22.9931 8.6146 22.4286 8.32698C21.7869 8 20.9468 8 19.2667 8H15.8C14.1198 8 13.2798 8 12.638 8.32698C12.0735 8.6146 11.6146 9.07354 11.327 9.63803Z" className={secondStrokeClassName} fill="#777786" />
        </svg>
    )
}
export const Business = ({ mainClassName, strokeClassName, secondStrokeClassName }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="44" viewBox="0 0 50 44" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M25 8.8002C17.7098 8.8002 11.8 14.71 11.8 22.0002C11.8 29.2904 17.7098 35.2002 25 35.2002C32.2901 35.2002 38.2 29.2904 38.2 22.0002C38.2 14.71 32.2901 8.8002 25 8.8002ZM10.2 22.0002C10.2 13.8264 16.8261 7.2002 25 7.2002C33.1738 7.2002 39.7999 13.8264 39.7999 22.0002C39.7999 30.174 33.1738 36.8002 25 36.8002C16.8261 36.8002 10.2 30.174 10.2 22.0002Z" className={secondStrokeClassName} fill="#777786" />
            <path fillRule="evenodd" clipRule="evenodd" d="M25 12.8002C19.9189 12.8002 15.8 16.9192 15.8 22.0002C15.8 27.0812 19.9189 31.2002 25 31.2002C30.081 31.2002 34.2 27.0812 34.2 22.0002C34.2 16.9192 30.081 12.8002 25 12.8002ZM14.2 22.0002C14.2 16.0355 19.0353 11.2002 25 11.2002C30.9646 11.2002 35.7999 16.0355 35.7999 22.0002C35.7999 27.9649 30.9646 32.8002 25 32.8002C19.0353 32.8002 14.2 27.9649 14.2 22.0002Z" className={secondStrokeClassName} fill="#777786" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21 17.2002C21.4418 17.2002 21.8 17.5584 21.8 18.0002V24.0002C21.8 25.7675 23.2326 27.2002 25 27.2002C26.7673 27.2002 28.2 25.7675 28.2 24.0002V18.0002C28.2 17.5584 28.5581 17.2002 29 17.2002C29.4418 17.2002 29.8 17.5584 29.8 18.0002V24.0002C29.8 26.6512 27.6509 28.8002 25 28.8002C22.349 28.8002 20.2 26.6512 20.2 24.0002V18.0002C20.2 17.5584 20.5581 17.2002 21 17.2002Z" className={strokeClassName} fill="#777786" />
            <path fillRule="evenodd" clipRule="evenodd" d="M25 15.2002C25.4418 15.2002 25.8 15.5584 25.8 16.0002V20.0002C25.8 20.442 25.4418 20.8002 25 20.8002C24.5581 20.8002 24.2 20.442 24.2 20.0002V16.0002C24.2 15.5584 24.5581 15.2002 25 15.2002Z" className={strokeClassName} fill="#777786" />
        </svg>
    )
}
export const Friends = ({ mainClassName, strokeClassName, secondStrokeClassName }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="44" viewBox="0 0 50 44" fill="none">
            <path d="M32.4108 23.5418C32.4108 28.1158 27.6947 32.9908 24.9999 32.9908C22.3052 32.9908 17.5891 28.1158 17.5891 23.5418C17.5891 18.9678 20.9072 15.2598 24.9999 15.2598C29.0927 15.2598 32.4108 18.9678 32.4108 23.5418Z" className={strokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M24.9999 32.9914C31.2138 32.9914 36.2511 28.075 36.2511 22.0103C36.2511 15.9457 31.2138 11.0293 24.9999 11.0293C18.7861 11.0293 13.7488 15.9457 13.7488 22.0103C13.7488 28.075 18.7861 32.9914 24.9999 32.9914Z" className={secondStrokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M31.3402 34.5459C29.4895 35.4689 27.32 35.9999 25.0003 35.9999C22.6806 35.9999 20.5111 35.4689 18.6604 34.5459" className={secondStrokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M17.762 21.7593L21.6902 23.5403" className={strokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M31.864 21.7593L27.9358 23.5403" className={strokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M25 11.028V8" className={secondStrokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M25 31.7473V30.4683" className={strokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M11.4006 25.2449C11.139 24.2089 11 23.1249 11 22.0099C11 20.8949 11.139 19.8109 11.4006 18.7749" className={secondStrokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M38.5996 25.2449C38.8612 24.2089 39.0002 23.1249 39.0002 22.0099C39.0002 20.8949 38.8612 19.8109 38.5996 18.7749" className={secondStrokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
        </svg>
    )
}

export const Profile = ({ mainClassName, strokeClassName, secondStrokeClassName }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="44" viewBox="0 0 50 44" fill="none">
            <path d="M24.9998 32.1879C35.1038 32.1879 35.7841 27.1521 35.7841 20.0427C35.7841 13.2745 31.213 8.5 24.9998 8.5C18.7866 8.5 14.2156 13.2734 14.2156 20.0427C14.2156 27.1521 14.957 32.1879 24.9998 32.1879Z" className={secondStrokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M14.2156 20.0437C14.2156 18.7164 14.3917 17.4669 14.7218 16.3135H13.4467C12.372 16.3135 11.4998 18.171 11.4998 20.4616C11.4998 22.7522 12.3709 24.6098 13.4467 24.6098H14.4424C14.2567 23.2231 14.2166 21.6885 14.2166 20.0426L14.2156 20.0437Z" className={strokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M36.5534 16.3135C35.4777 16.3135 35.2783 16.3135 35.2783 16.3135C35.6074 17.4658 35.7846 18.7164 35.7846 20.0437C35.7846 21.6895 35.7477 23.2242 35.5663 24.6108H36.5534C37.6282 24.6108 38.5004 22.7533 38.5004 20.4627C38.5004 18.1721 37.6292 16.3135 36.5534 16.3135Z" className={strokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M32.3849 23.3862C32.754 22.3311 32.2198 21.17 31.1916 20.7929C30.1634 20.4157 29.0307 20.9652 28.6615 22.0203C28.2924 23.0754 28.8267 24.2364 29.8549 24.6136C30.883 24.9908 32.0158 24.4412 32.3849 23.3862Z" className={strokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M30.9824 22.8711L35.3235 24.463" className={strokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M31.1907 34.4902C29.6751 35.1447 27.6649 35.5 24.9996 35.5C22.3334 35.5 20.3231 35.1447 18.8086 34.4902" className={secondStrokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M35.2777 25.6001C32.9405 27.4069 29.2068 28.5765 24.9997 28.5765C20.7925 28.5765 17.0589 27.4069 14.7217 25.6001" className={strokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M35.4255 16.8757C33.0999 15.0031 29.2967 13.7827 24.9999 13.7827C20.703 13.7827 16.8998 15.0031 14.5742 16.8757" className={strokeClassName} stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
        </svg>
    )
}

export const AirdropIcon = ({ mainClassName }) => {
    return (
        <svg className={mainClassName} width="166" height="166" viewBox="0 0 166 166" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2205_1313)">
                <rect width="166" height="166" rx="83" fill="url(#paint0_linear_2205_1313)" />
                <circle cx="82.9999" cy="82.9999" r="63.4706" stroke="#FFDB95" stroke-width="3.90588" stroke-linecap="round" />
                <g filter="url(#filter0_d_2205_1313)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M83 156.235C123.447 156.235 156.235 123.447 156.235 83C156.235 42.5533 123.447 9.76471 83 9.76471C42.5533 9.76471 9.76471 42.5533 9.76471 83C9.76471 123.447 42.5533 156.235 83 156.235ZM83 166C128.84 166 166 128.84 166 83C166 37.1604 128.84 0 83 0C37.1604 0 0 37.1604 0 83C0 128.84 37.1604 166 83 166Z" fill="url(#paint1_linear_2205_1313)" />
                </g>
                <g filter="url(#filter1_d_2205_1313)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M86.8448 31.8874C84.523 28.4295 79.7082 28.4295 77.3864 31.8874C71.0869 41.2691 67.6608 52.4995 67.5721 64.037L57.5121 69.1134C52.1945 71.7968 48.8237 77.3636 48.8237 83.4622V87.6614V92.9876V99.2C48.8237 100.671 49.9814 101.863 51.4096 101.863C52.8377 101.863 53.9954 100.671 53.9954 99.2V92.9876H67.5703V95.8706C67.5703 99.548 70.3612 102.529 73.804 102.529H90.4272C93.8699 102.529 96.6608 99.548 96.6608 95.8706V92.9876H112.005V99.2C112.005 100.671 113.163 101.863 114.591 101.863C116.019 101.863 117.177 100.671 117.177 99.2V92.9876V87.6614V83.4622C117.177 77.3636 113.806 71.7968 108.488 69.1134L97.7996 63.7197C97.4296 63.533 97.0375 63.4456 96.6516 63.4475C96.4528 52.1173 93.0369 41.1092 86.8448 31.8874Z" fill="#FFDB95" />
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M83.0002 29.3652C80.9402 29.031 78.7398 29.8718 77.3864 31.8874C71.0869 41.2691 67.6608 52.4995 67.5721 64.037L57.5121 69.1134C52.1945 71.7968 48.8237 77.3636 48.8237 83.4622V87.6614V92.9876V99.2C48.8237 100.671 49.9814 101.863 51.4096 101.863C52.8377 101.863 53.9954 100.671 53.9954 99.2V92.9876H67.5703V95.8706C67.5703 99.548 70.3612 102.529 73.804 102.529H83.0002V29.3652Z" fill="#FFC24B" />
                <path d="M83 114.735L83 144.029" stroke="#FFDB95" stroke-width="3.90588" stroke-linecap="round" />
                <path d="M68.353 114.735L68.353 134.265" stroke="#FFDB95" stroke-width="3.90588" stroke-linecap="round" />
                <path d="M97.647 114.735L97.647 134.265" stroke="#FFDB95" stroke-width="3.90588" stroke-linecap="round" />
                <path d="M51.2649 41.5L53.0256 47.0628L58.5885 48.8235L53.0256 50.5842L51.2649 56.1471L49.5042 50.5842L43.9414 48.8235L49.5042 47.0628L51.2649 41.5Z" fill="#FFDB95" />
                <path d="M51.2649 41.5L53.0256 47.0628L58.5885 48.8235L53.0256 50.5842L51.2649 56.1471L49.5042 50.5842L43.9414 48.8235L49.5042 47.0628L51.2649 41.5Z" fill="#FFDB95" />
                <path d="M51.2649 41.5V56.1471L49.5042 50.5842L43.9414 48.8235L49.5042 47.0628L51.2649 41.5Z" fill="#FFB31F" />
                <path d="M51.2649 56.1473L49.5042 50.5844L43.9414 48.8237L51.2649 48.8237V56.1473Z" fill="#BD7D00" />
                <path d="M51.2648 56.1473L53.0255 50.5844L58.5884 48.8237L51.2648 48.8237V56.1473Z" fill="#E1B96D" />
                <path d="M112.294 53.7061L113.468 57.4146L117.176 58.5884L113.468 59.7622L112.294 63.4708L111.12 59.7622L107.412 58.5884L111.12 57.4146L112.294 53.7061Z" fill="#FFDB95" />
                <path d="M112.294 53.7061L113.468 57.4146L117.176 58.5884L113.468 59.7622L112.294 63.4708L111.12 59.7622L107.412 58.5884L111.12 57.4146L112.294 53.7061Z" fill="#FFDB95" />
                <path d="M112.294 53.7061V63.4708L111.12 59.7622L107.412 58.5884L111.12 57.4146L112.294 53.7061Z" fill="#FFB31F" />
                <path d="M112.294 63.4707L111.12 59.7622L107.412 58.5884L112.294 58.5884V63.4707Z" fill="#BD7D00" />
                <path d="M112.294 63.4707L113.468 59.7622L117.176 58.5884L112.294 58.5884V63.4707Z" fill="#E1B96D" />
            </g>
            <defs>
                <filter id="filter0_d_2205_1313" x="0" y="0" width="166" height="168.441" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.44118" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.646826 0 0 0 0 0.474641 0 0 0 0 0.212777 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2205_1313" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2205_1313" result="shape" />
                </filter>
                <filter id="filter1_d_2205_1313" x="48.8237" y="29.2939" width="68.353" height="74.7001" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1.46471" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.646826 0 0 0 0 0.474641 0 0 0 0 0.212777 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2205_1313" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2205_1313" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_2205_1313" x1="83" y1="6.10294" x2="83" y2="166" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C5882E" />
                    <stop offset="1" stop-color="#F8BB45" />
                </linearGradient>
                <linearGradient id="paint1_linear_2205_1313" x1="83" y1="0" x2="83" y2="166" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFDB95" />
                    <stop offset="0.505" stop-color="#FFF0D3" />
                    <stop offset="1" stop-color="#BA7B01" />
                </linearGradient>
                <clipPath id="clip0_2205_1313">
                    <rect width="166" height="166" rx="83" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}