import { useContext, useEffect, useState } from 'react';
import { Coin, CoinGrey } from '../../../assets/icons/icons';
import '../FriendsCard/FriendsCard.css';
import { motion } from 'framer-motion';
import { AVATARS } from '../../../assets/utils/utils';
import mainApi from '../../../assets/api/MainApi';
import { TranslationContext } from '../../../assets/contexts/translationContext';

function FriendsCard({ item, preloader }) {

    const {
        friendsCard: {
            errorText,
            inviteText,
            invitedText,
        },
    } = useContext(TranslationContext);

    const [invited, setInvited] = useState(false)
    const [invitedPreloader, setInvitedPreloader] = useState(false)
    const [error, setError] = useState(false)

    function handleInvite() {
        setInvitedPreloader(true)
        // setInvited(true)
        mainApi
            .inviteFriend({ id: item.id })
            .then((res) => {
                console.log(res);

                setInvited(true)
                window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft')
            })
            .catch((err) => {
                console.log(err);
                setInvitedPreloader(false)
                setError(true)
            })
            .finally(() => {
                setInvitedPreloader(false)
            })
    }

    return (
        <div className={`${preloader ? 'friends-card_animated-gradient' : ''} friends-card`}>
            <div className='frineds-card__left'>
                <img src={AVATARS[item.balance_data?.level - 1]} alt='' className='friends-card__img'></img>
                <div className={`friends-card__name-box`}>
                    <p className='friends-card__name'>{item.user_data?.username ? item.user_data?.username : item.user_data?.first_name}</p>
                    <p className='friends-card__bonus'><CoinGrey />+5000</p>
                </div>
            </div>
            <div className='frineds-card__right'>
                <div className='frineds-card__right__blur'></div>
                <p className='friends-card__income'>
                    {Intl.NumberFormat('en-US', {
                        notation: "compact",
                        maximumFractionDigits: 2
                    }).format(Number(item.balance_data?.total_income))}
                    <Coin mainClassName={'friends-card__coin'} />
                </p>
                <div className='friends-card__btn-box'>
                    {invitedPreloader ?
                        <motion.div className='friends-card__btn__animated-gradient' />
                        : null}
                    <button onClick={() => handleInvite()} className={`friends-card__btn ${error ? 'friends-card__btn_error' : ''} ${invited ? 'friends-card__btn_yellow' : ''}`}>
                        {error ? errorText
                            : !invited ?
                                inviteText
                                : invitedText}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FriendsCard;