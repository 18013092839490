import '../ProfilePopup/ProfilePopup.css';
import TemplatePopup from '../../TemplatePopup/TemplatePopup';
import { motion } from 'framer-motion'

function ProfilePopup({ isOpen, setOpen, data, current, language }) {
    const curCoupon = current

    return (
        <>
            {isOpen ?
                <motion.div
                    onClick={() => setOpen(!isOpen)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className='popup__bg' />
                : null}
            <TemplatePopup secondClassName={'profile-popup'} isOpen={isOpen} setOpen={setOpen}>
                <img src={curCoupon?.coupon?.image} alt='' className='profile-popup__img'></img>
                <p className='profile-popup__text'>{curCoupon?.coupon?.title_localized[language] ? curCoupon?.coupon?.title_localized[language] : curCoupon?.coupon?.title}</p>
                <p className='profile-popup__desc'>{curCoupon?.coupon?.description_localized[language] ? curCoupon?.coupon?.description_localized[language] :curCoupon?.coupon?.description}</p>
                <p className='profile-popup__code'>{curCoupon?.code}</p>
            </TemplatePopup>
        </>
    );
}

export default ProfilePopup;