import '../ProfileInfo/ProfileInfo.css';
import { motion } from "framer-motion"
import { format } from "date-fns";
import SignUp from '../Auth/SignUp/SignUp';
import { useContext, useEffect, useState } from 'react';
import mainApi from '../../../assets/api/MainApi';
import { TranslationContext } from '../../../assets/contexts/translationContext';

function ProfileInfo({ personalData, setPersonalData, setSteps, step, setTotal, user, total, personalBonus, setPersonalBonus }) {

    const {
        profileInfo: {
            editBtn,
            hint,
            nameTitle,
            surnameTitle,
            genderTitle,
            dateOfBirthTitle,
            emailTitle,
            maleValue,
            femaleValue,
        },
    } = useContext(TranslationContext);

    const [preloaders, setPreloader] = useState(false)

    useEffect(() => {
        setPreloader(true)
        mainApi
            .getMe()
            .then((res) => {
                setPersonalData(res?.user_data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloader(false)
            })
    }, [step])

    return (
        <>
            {!personalData && step === 1 ? <p className='profile__info-desc'>{hint}</p> : ''}
            {personalData && step === 2 ?
                <>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className='profile__info'>
                        {preloaders ?
                            <div className='animated-gradient'></div>
                            :
                            <>
                                <div>
                                    <p className='profile__info__text_small'>{nameTitle}</p>
                                    <p className='profile-info__text'>{personalData.first_name === null || personalData.first_name === '' ? '-' : personalData.first_name}</p>
                                </div>
                                <div>
                                    <p className='profile__info__text_small'>{surnameTitle}</p>
                                    <p className='profile-info__text'>{personalData.last_name === null || personalData.last_name === '' ? '-' : personalData.last_name}</p>
                                </div>
                                <div>
                                    <p className='profile__info__text_small'>{genderTitle}</p>
                                    <p className='profile-info__text'>{personalData.gender === null ? '-' : personalData.gender === true ? maleValue : femaleValue}</p>
                                </div>
                                <div>
                                    <p className='profile__info__text_small'>{dateOfBirthTitle}</p>
                                    <p className='profile-info__text'>{personalData.date_of_birth === null || personalData.date_of_birth === '' ? '-' : format(new Date(personalData.date_of_birth), "dd.MM.yyyy")}</p>
                                </div>
                                <div>
                                    <p className='profile__info__text_small'>{emailTitle}</p>
                                    <p className='profile-info__text'>{personalData.email === null || personalData.email === '' ? '-' : personalData.email}</p>
                                </div>
                            </>}
                    </motion.div>
                    <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        onClick={() => setSteps(0)}
                        className={`profile__info__btn_edit`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <path d="M10.4952 20C9.12764 20 7.84075 19.7386 6.63449 19.2159C5.42824 18.6996 4.36389 17.98 3.44146 17.0571C2.52548 16.1342 1.80624 15.0694 1.28374 13.8625C0.761248 12.6557 0.5 11.3682 0.5 10C0.5 8.63182 0.761248 7.3443 1.28374 6.13746C1.80624 4.93062 2.52548 3.86899 3.44146 2.95257C4.36389 2.02969 5.42501 1.30687 6.62482 0.784124C7.83108 0.261375 9.11796 0 10.4855 0C11.8595 0 13.1496 0.261375 14.3558 0.784124C15.5621 1.30687 16.6264 2.02969 17.5489 2.95257C18.4713 3.86899 19.1938 4.93062 19.7163 6.13746C20.2388 7.3443 20.5 8.63182 20.5 10C20.5 11.3682 20.2388 12.6557 19.7163 13.8625C19.1938 15.0694 18.4713 16.1342 17.5489 17.0571C16.6264 17.98 15.5621 18.6996 14.3558 19.2159C13.1496 19.7386 11.8627 20 10.4952 20ZM7.39889 14.3272L13.814 7.909L12.5271 6.62149L6.12167 13.03L5.56047 14.3756C5.49597 14.524 5.51855 14.6531 5.62821 14.7628C5.74432 14.8661 5.8701 14.8919 6.00556 14.8403L7.39889 14.3272ZM14.4236 7.30881L15.1589 6.58277C15.3331 6.40852 15.4234 6.22459 15.4298 6.03098C15.4363 5.83091 15.3589 5.65021 15.1976 5.48887L14.9557 5.24685C14.788 5.08551 14.6074 5.01129 14.4139 5.0242C14.2204 5.03065 14.0398 5.11778 13.872 5.28558L13.1367 6.0213L14.4236 7.30881Z" fill="#FFB31F" />
                        </svg>
                        {editBtn}
                    </motion.button>
                </>
                :
                <SignUp
                    setSteps={setSteps}
                    setTotal={setTotal}
                    user={user}
                    total={total}
                    personalBonus={personalBonus}
                    setPersonalBonus={setPersonalBonus}
                    step={step}
                    personalData={personalData}
                />
            }

        </>
    );
}

export default ProfileInfo;