import { createContext } from 'react';
import { RU_TRANSLATION } from '../translation/RU';
import { EN_TRANSLATION } from '../translation/EN';

export const TranslationContext = createContext();

export const translations = {
  ru: RU_TRANSLATION,
  en: EN_TRANSLATION,
};
