import '../MenuCardPopup/MenuCardPopup.css';
import TemplatePopup from '../../TemplatePopup/TemplatePopup';
import { Coin, CoinGrey, CoinWhite } from '../../../assets/icons/icons';
import { motion } from 'framer-motion';
import noPhoto from '../../../assets/images/NoPhoto.png'
import { useContext } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';

function MenuCardPopup({ isOpen, setOpen, data, currentId, language }) {

    const {
        menuCardPopup: {
            incomeText,
            levelText,
            purchasesText,
        },
    } = useContext(TranslationContext);

    const curMenuCard = data?.find(item => item.id === currentId)

    return (
        data ?
            <>
                {isOpen ?
                    <motion.div
                        onClick={() => setOpen(!isOpen)}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className='popup__bg' />
                    : null}
                <TemplatePopup secondClassName={'popup-business'} isOpen={isOpen} setOpen={setOpen}>
                    {curMenuCard?.image ?
                        <img className='menu-card-popup__img' src={curMenuCard?.image} />
                        :
                        <img src={noPhoto} alt='' className='menu-card-popup__img'></img>
                    }
                    <p className='menu-card-popup__title'>{curMenuCard ? curMenuCard.title_localized && curMenuCard.title_localized[language] ? curMenuCard.title_localized[language] : curMenuCard.title : ''} {curMenuCard ? curMenuCard.measure_localized && curMenuCard.measure_localized[language] ? curMenuCard.measure_localized[language] : curMenuCard.timeasuretle : ''}</p>
                    <div className='menu-card-popup__current-info-box'>
                        <p>lvl {curMenuCard?.current_level}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="18" viewBox="0 0 2 18" fill="none">
                            <path d="M1 0V18" stroke="#8B8D9B" />
                        </svg>
                        <p className='menu-card-popup__current-info-box__farm'>
                            <CoinWhite />
                            +{curMenuCard?.levels[curMenuCard?.current_level].income_an_hour}
                        </p>
                    </div>
                    <div className='menu-card-popup__table-box'>
                        <div className='menu-card-popup__table-box__header'>
                            <p className='menu-card-popup__table-box__header__title'>{levelText}</p>
                            <p className='menu-card-popup__table-box__header__title menu-card-popup__table__text_align-center'>{purchasesText}</p>
                            <p></p>
                            <p className='menu-card-popup__table-box__header__title menu-card-popup__table__text_align-end'>{incomeText}</p>
                        </div>
                        {curMenuCard?.levels.map((item, i) => {
                            return (
                                item.level === 0 ?
                                    null
                                    :
                                    <div key={i} className={`menu-card-popup__table__line ${item.level === curMenuCard?.current_level ? 'menu-card-popup__table__line_current' : ''}`}>
                                        <div>{item?.level}</div>
                                        <div className='menu-card-popup__table__text_align-center'>{item?.needed}</div>
                                        <p className={`menu-card-popup__table__text_align-end menu-card-popup__table__line__arrow ${item.level === curMenuCard?.current_level ? 'menu-card-popup__table__line_current' : ''}`}>→</p>
                                        <div className='menu-card-popup__table__text_align-end'>
                                            {item.level === curMenuCard?.current_level ?
                                                <CoinWhite mainClassName={'menu-card-popup__table__line__coin'} />
                                                : <CoinGrey mainClassName={'menu-card-popup__table__line__coin'} />}
                                            +{item?.income_an_hour}
                                        </div>
                                    </div>
                            )
                        })}
                    </div>
                </TemplatePopup>
            </>
            : null
    );
}

export default MenuCardPopup;