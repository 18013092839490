import '../Business/Business.css';
import { motion } from 'framer-motion';
import BusinessCard from './BusinessCard/BusinessCard';
import { useContext, useEffect, useState } from 'react';
import { BUSINESS_CARD } from '../../assets/utils/constants';
import BusinessPopup from './BusinessPopup/BusinessPopup';
import mainApi from '../../assets/api/MainApi';
import { TranslationContext } from '../../assets/contexts/translationContext';

function Business({ total, setTotal, user, language }) {
    const {
        business: {
            title,
        },
    } = useContext(TranslationContext);

    const [isOpen, setOpen] = useState(false)
    const [currentId, setCurrentId] = useState()
    const [currentCategory, setCurrentCategory] = useState()
    const [currentDataId, setCurrentDataId] = useState()
    const [business, setBusiness] = useState([])
    const [preloader, setPreloader] = useState(false)

    useEffect(() => {
        setCurrentDataId(business?.find(item => item.id === currentCategory)?.items)
    }, [currentCategory, currentId])

    useEffect(() => {
        setOpen(false)
    }, [currentCategory])

    useEffect(() => {
        if (!user) return
        setPreloader(true)
        mainApi
            .getBusiness()
            .then((res) => {
                setBusiness(res.data);
                console.log(res.data);
                setCurrentCategory(res.data[0].id)
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setPreloader(false)
            })
        // setBusiness(BUSINESS_CARD)
    }, [user])

    function handleNavClick(id) {
        window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft')
        setCurrentCategory(id)
    }

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                // transition={{ duration: 0.5 }}
                className="business">
                <p className='business__title'>{title}</p>
                {preloader ?
                    <div className={`business__nav-block business__nav-block_animated-gradient
                    ${business.length > 2 ? 'business__nav-block_more-two' : ''}
                    ${business.length > 3 ? 'business__nav-block_more-four' : ''}`}></div>
                    :
                    <div className={`business__nav-block
                     ${business.length > 2 ? 'business__nav-block_more-two' : ''}
                     ${business.length > 3 ? 'business__nav-block_more-four' : ''}`}>
                        {business?.map((item, i) => {
                            return (
                                <p className={`business__nav 
                                ${item.id === currentCategory ? 'business__nav_active' : ''}
                                 ${business.length > 2 ? 'business__nav_more-two' : ''} 
                                 ${business.length > 3 ? 'business__nav_more-four' : ''}`}
                                    onClick={() => handleNavClick(item.id)}>
                                    {item.title_localized[language] ? item.title_localized[language] : item.title}
                                </p>
                            )
                        })}
                    </div>
                }
                {preloader ?
                    <div className='business__cards-grid'>
                        {Array(4).fill('').map((item, i) => {
                            return (
                                <BusinessCard
                                    key={i}
                                    setCurrentId={setCurrentId}
                                    setOpen={setOpen}
                                    isOpen={isOpen}
                                    item={item}
                                    setBusiness={setBusiness}
                                    preloader={true}
                                    language={language}
                                />
                            )
                        })}
                    </div>
                    :
                    <div className='business__cards-grid'>
                        {business?.map((item, i) => {
                            return (
                                item?.id === currentCategory ?
                                    item?.items?.map((item, i) => {
                                        return (
                                            <>
                                                <BusinessCard
                                                    key={i}
                                                    setCurrentId={setCurrentId}
                                                    setOpen={setOpen}
                                                    isOpen={isOpen}
                                                    item={item}
                                                    setBusiness={setBusiness}
                                                    language={language}
                                                />
                                            </>
                                        )
                                    })
                                    : null
                            )
                        })}
                    </div>
                }
                <div className='business__blur'></div>
            </motion.div>
            {currentDataId !== undefined ?
                <BusinessPopup
                    isOpen={isOpen}
                    setOpen={setOpen}
                    data={currentDataId}
                    currentId={currentId}
                    total={total}
                    setTotal={setTotal}
                    setCurrentId={setCurrentId}
                    setBusiness={setBusiness}
                    business={business}
                    currentCategory={currentCategory}
                    language={language}
                />
                : null}
        </>
    );
}

export default Business;