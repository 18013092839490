import './LearnPopup.css';
import { motion } from "framer-motion"
import learnBg from '../../assets/images/learnBg.webp'
import TemplatePopup from '../TemplatePopup/TemplatePopup';
import { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import learn1 from '../../assets/images/leran-1.webp'
import learn2 from '../../assets/images/learn-2.webp'
import learn3EN from '../../assets/images/learn-3-en.webp'
import learn3 from '../../assets/images/learn-3.webp'
import learn4EN from '../../assets/images/learn-4-en.webp'
import learn4 from '../../assets/images/learn-4.webp'
import learn5 from '../../assets/images/learn-5.webp'
import learn6 from '../../assets/images/learn-6.webp'
import { LEARN } from '../../assets/utils/constants';
import mainApi from '../../assets/api/MainApi';
import { TranslationContext } from '../../assets/contexts/translationContext';

function LearnPopup({ learnPopup, setLearnPopup, setLearn, learn, setUser, language }) {

    const {
        learnPopup: {
            next,
            start,
            slides,
        },
    } = useContext(TranslationContext);
    const CARDS_WITH_TRANSLATION = LEARN.map((item, i) => {
        return {
            ...item,
            ...slides[i],
        }
    })


    const [isEnd, setEnd] = useState(false)
    const [swiper, setSwiper] = useState(false)
    const [active, setActive] = useState(0)

    function handleSwiperNext() {
        if (swiper) {
            window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft')
            swiper?.slideNext()
            if (isEnd) {
                mainApi
                    .setRegistered()
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        // setPreloaders(false)
                    })
                setLearnPopup(false)
                setUser((prevValue) => ({
                    ...prevValue,
                    register_stage: 1
                }))

                setLearn(1)
                window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft')
            }
        }
    }

    const variants = {
        open: {
            // y: "0%",
            opacity: 1,
            transition: { duration: 0.5 },
        },
        closed: {
            // y: "100%",
            opacity: 0,
            transition: { duration: 0.5 },
            // transitionEnd: {
            //     display: "none",
            // },
        }
    };
    const variantsIcon = {
        open: {
            // y: "0%",
            scale: 1,
            opacity: 1,
            transition: { duration: 0.5 },
        },
        closed: {
            scale: 0,
            // y: "100%",
            opacity: 0,
            transition: { duration: 0.5 },
        }
    };

    return (
        <motion.div
            initial={false}
            animate={learnPopup ? "open" : "closed"}
            variants={variants}
            className={`${learnPopup ? 'learn-popup_visible' : 'learn-popup_unvisible'} learn-popup`}
        >
            <img src={learnBg} alt='' className='learn-popup__img'></img>
            <motion.img
                animate={active === 0 ? "open" : "closed"}
                variants={variantsIcon}
                alt='' src={learn1}
                className='learn-popup__img_center learn-popup__img_center-first'
            />
            <motion.img
                animate={active === 1 ? "open" : "closed"}
                variants={variantsIcon}
                alt='' src={learn2}
                className='learn-popup__img_center learn-popup__img_center-second'
            />
            <motion.img
                animate={active === 2 ? "open" : "closed"}
                variants={variantsIcon}
                alt='' src={language === 'ru'? learn3 : learn3EN}
                className='learn-popup__img_center'
            />
            <motion.img
                animate={active === 3 ? "open" : "closed"}
                variants={variantsIcon}
                alt='' src={language === 'ru'? learn4 : learn4EN}
                className='learn-popup__img_center learn-popup__img_center-four'
            />
            <motion.img
                animate={active === 4 ? "open" : "closed"}
                variants={variantsIcon}
                alt='' src={learn5}
                className='learn-popup__img_center learn-popup__img_center-five'
            />
            <motion.img
                animate={active === 5 ? "open" : "closed"}
                variants={variantsIcon}
                alt='' src={learn6}
                className='learn-popup__img_center learn-popup__img_center-six'
            />

            <TemplatePopup secondClassName={'learn-popup__templ'} isOpen={learnPopup} setOpen={setLearnPopup}>
                <Swiper
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    className='learn-popup__swiper'
                    onInit={(evt) => setSwiper(evt)}
                    onReachEnd={() => setEnd(true)}
                    onSlideChange={() => setActive(swiper.activeIndex)}
                >
                    {CARDS_WITH_TRANSLATION.map((item, i) => {
                        return (
                            <SwiperSlide key={i}>
                                {item.icon}
                                <div className='learn-popup__text'>
                                    {item.title}
                                    <p className='learn-popup__subtext'>
                                        {item.text}
                                    </p>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <button
                    onClick={() => handleSwiperNext()}
                    className={`learn-popup__swiper__btn ${isEnd ? 'learn-popup__swiper__btn_end' : ''}`}>
                    {isEnd ? start : next}
                </button>
            </TemplatePopup>
        </motion.div>
    );
}

export default LearnPopup