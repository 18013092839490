import '../App/App.css';
import { useEffect, useState } from 'react';
import Main from '../Main/Main';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../Header/Header';
import {
  TranslationContext,
  translations,
} from '../../assets/contexts/translationContext';
import NavBlock from '../NavBlock/NavBlock';
import Menu from '../Menu/Menu';
import Business from '../Business/Business';
import Loading from '../Loading/Loading';
import Friends from '../Friends/Friends';
import Rating from '../Raiting/Raiting';
import IncomePopup from '../IncomePopup/IncomePopup';
import LearnPopup from '../LearnPopup/LearnPopup';
import Profile from '../Profile/Profile';
import PhonePopup from '../PhonePopup/PhonePopup';
import GiftPopup from '../GiftPopup/GiftPopup';
import Rewards from '../Rewards/Rewards';
import LevelUpPopup from '../LevelUpPopup/LevelUpPopup';
import mainApi from '../../assets/api/MainApi';
import Count from '../Count/Count';
import { AWARDS_LIMIT, TASK, maxNumberForNoUsernameHash, minNumberForNoUsernameHash, niknames } from '../../assets/utils/constants';
import ForComputer from '../ForComputer/ForComputer';
import Airdrop from '../Airdrop/Airdrop';

function App() {
  const tg = window?.Telegram?.WebApp
  const language = tg?.initDataUnsafe?.user?.language_code ? tg?.initDataUnsafe?.user?.language_code === 'ru' ? 'ru' : 'en' : 'en'
  const location = useLocation()
  const navigate = useNavigate();

  const [preloaders, setPreloaders] = useState({
    app: true,
    rewards: true
  })
  const [success, setSucces] = useState(true)
  const [user, setUser] = useState(null)
  const [clickable, setClickable] = useState(true)

  const [energyCounter, setEnergyCounter] = useState(0)

  const [income, setIncome] = useState(0)
  const [learn, setLearn] = useState()
  const [personalData, setPersonalData] = useState(false)

  const [isOpenRating, setOpenRating] = useState(false);
  const [isOpenRewards, setOpenRewards] = useState(false);
  const [isOpenLevelUp, setOpenLevelUp] = useState(false);
  const [isGiftOpen, setGiftOpen] = useState(false);

  const [tabOpen, setTabOpen] = useState(false)
  const [phoneOpen, setPhoneOpen] = useState(false)
  const [learnPopup, setLearnPopup] = useState(false)
  const [raitingNumber, setRaitingNumber] = useState(0)
  const [countVisible, setCountVisible] = useState(true)
  const [card, setCard] = useState(null)
  const [rewards, setRewards] = useState([])
  const [personalBonus, setPersonalBonus] = useState(false)
  const [total, setTotal] = useState({
    initial: 0,
    persec: 0,
    click: 0
  })

  const [searchParams, setSearchParams] = useSearchParams();
  const tgParams = searchParams.get("tgWebAppStartParam");
  const randomIndex = Math.floor(Math.random() * (niknames.length - 1));
  const randomNumber = Math.floor(Math.random() * (maxNumberForNoUsernameHash - minNumberForNoUsernameHash + 1) + minNumberForNoUsernameHash);

  useEffect(() => {
    if (tg) {
      tg?.expand();
      tg?.ready(); // MAY BE COLLBACK TO CHECK IF READY?
      tg.headerColor = '#1E1E2D'
      tg.backgroundColor = '#1E1E2D'
      navigate('/')
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setPreloaders((prevState) => ({
        ...prevState,
        app: !preloaders.app
      }))
    }, 6000);
  }, [success])

  useEffect(() => {
    document.body.style.overflow = "initial"
    mainApi
      .getMe()
      .then((res) => {
        console.log(res);
        if (res.short_id !== tg?.initDataUnsafe?.user?.id) {
          mainApi
            .authLogin({
              // initData: `query_id=AAEyeS0XAAAAADJ5LRetptp9&user=%7B%22id%22%3A388856114%2C%22first_name%22%3A%22%D0%9B%D1%91%D1%88%D0%B0%22%2C%22last_name%22%3A%22%D0%A1%D1%83%D1%80%D0%B8%D0%BA%D0%BE%D0%B2%22%2C%22username%22%3A%22SurikovAleksey%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1722432860&hash=b16997fa9bb1bbeaa8913522e83b70e3166411617f069a00b4526ec7cbf4a804`,
              // initData: `query_id=AAEIg_wYAAAAAAiD_BjI2fVc&user=%7B%22id%22%3A419201800%2C%22first_name%22%3A%22Masha%22%2C%22last_name%22%3A%22Yumasheva%22%2C%22username%22%3A%22yumassheva%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1724308292&hash=6cd00dab40c01762ea40d5ec3ad471d280e935c5a637534b893a4361ba02ad46`,
              // initData: `query_id=AAGohe8ZAAAAAKiF7xkDM3H6&user=%7B%22id%22%3A435127720%2C%22first_name%22%3A%22%D0%9C%D0%B8%D1%88%D0%B0%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22mxlcz%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1725210082&hash=bf38864ceb70cec9912e305e32709e7a4a87c28701f9960db5093ded21b76faa`,
              initData: `query_id=AAEIg_wYAAAAAAiD_BhgSIhW&user=%7B%22id%22%3A419201800%2C%22first_name%22%3A%22Masha%22%2C%22last_name%22%3A%22Yumasheva%22%2C%22username%22%3A%22yumassheva%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1727098871&hash=dc0e895b0e63ee2a2d2bceb13b781fad0e6d74e9ac835c363945d9ad4ba54fb4`,            
              // initData: tg?.initData,
              refId: tgParams
            })
            .then((res) => {
              setUser(res)
              console.log(res)
            })
            .catch((err) => {
              console.log(err);
              setSucces(false)
            })
        }
        else {
          setUser(res)
        }
      })
      .catch((err) => {
        console.log(err);
        mainApi
          .authLogin({
            // initData: `query_id=AAEyeS0XAAAAADJ5LRetptp9&user=%7B%22id%22%3A388856114%2C%22first_name%22%3A%22%D0%9B%D1%91%D1%88%D0%B0%22%2C%22last_name%22%3A%22%D0%A1%D1%83%D1%80%D0%B8%D0%BA%D0%BE%D0%B2%22%2C%22username%22%3A%22SurikovAleksey%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1722432860&hash=b16997fa9bb1bbeaa8913522e83b70e3166411617f069a00b4526ec7cbf4a804`,
            // initData: `query_id=AAEIg_wYAAAAAAiD_BjI2fVc&user=%7B%22id%22%3A419201800%2C%22first_name%22%3A%22Masha%22%2C%22last_name%22%3A%22Yumasheva%22%2C%22username%22%3A%22yumassheva%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1724308292&hash=6cd00dab40c01762ea40d5ec3ad471d280e935c5a637534b893a4361ba02ad46`,
            // initData: `query_id=AAGohe8ZAAAAAKiF7xkDM3H6&user=%7B%22id%22%3A435127720%2C%22first_name%22%3A%22%D0%9C%D0%B8%D1%88%D0%B0%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22mxlcz%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1725210082&hash=bf38864ceb70cec9912e305e32709e7a4a87c28701f9960db5093ded21b76faa`,
            // initData: `query_id=AAEIg_wYAAAAAAiD_BhgSIhW&user=%7B%22id%22%3A419201800%2C%22first_name%22%3A%22Masha%22%2C%22last_name%22%3A%22Yumasheva%22%2C%22username%22%3A%22yumassheva%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1727098871&hash=dc0e895b0e63ee2a2d2bceb13b781fad0e6d74e9ac835c363945d9ad4ba54fb4`,            
            initData: tg?.initData,
            refId: tgParams
          })
          .then((res) => {
            setUser(res)
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
            setSucces(false)
          })
      })
  }, [])

  useEffect(() => {
    if (!user) return
    setTotal((prevState) => ({
      ...prevState,
      initial: Number(user.balance_data?.balance),
      persec: Number(user?.balance_data?.income_an_hour)
    }))
    setEnergyCounter(Number(user?.balance_data?.energy))

    setIncome(user.income_since_last_request)
    setLearn(user?.register_stage)
    setRaitingNumber(user?.balance_data?.rating)

    if (user?.user_data?.first_name ||
      user?.user_data?.last_name ||
      user?.user_data?.date_of_birth ||
      user?.user_data?.email) {
      setPersonalData(user?.user_data)
    }

    if (user?.user_data?.phone === null) {
      setPhoneOpen(true)
    }

    if (user?.user_data?.username === null) {
      setUser((prevValue) => ({
        ...prevValue,
        user_data: {
          ...prevValue.user_data,
          username: `${niknames[randomIndex][language]}#${randomNumber}`
        }
      }))
    }
    setPreloaders((prevState) => ({
      ...prevState,
      rewards: true
    }))
    mainApi.getAwards({ limit: AWARDS_LIMIT })
      .then((res) => {
        console.log(res.data)
        setRewards(res.data)
        // setRewards(TASK)
        if (user?.user_data?.phone) {
          mainApi
            .getCard()
            .then((res) => {
              setCard(res)
            })
            .catch((err) => {
              console.log(err);
            })
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setPreloaders((prevState) => ({
          ...prevState,
          rewards: false
        }))
      })

  }, [user])

  useEffect(() => {
    if (isOpenRating || preloaders.app || isOpenRewards || learn === 0 || phoneOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial"
    }
    // console.log(isOpenRating, preloaders.app, isOpenRewards, learn === 0, phoneOpen)
  }, [location, isOpenRating, isOpenRewards, learn, preloaders.app, phoneOpen]);

  useEffect(() => {
    if (!user) return
    if (user?.register_stage === 0) {
      setGiftOpen(true)
      setLearnPopup(true)
    }
  }, [user?.register_stage]);

  useEffect(() => {
    if (!user) return
    setTimeout(() => {
      mainApi
        .getMe()
        .then((res) => {
          if (res.received_profile_bonus !== user?.received_profile_bonus && Number(res.balance_data.balance) !== Number(user?.balance_data.balance)) {
            setUser((prevValue) => ({
              ...prevValue,
              user_data: {
                ...personalData,
                username: prevValue.user_data.username
              },
              balance_data: {
                ...res.balance_data,
                balance: Number(res.balance_data.balance),
                energy: Number(res.balance_data.energy),
                energy_an_hour: Number(res.balance_data.energy_an_hour),
                income_an_hour: Number(res.balance_data.income_an_hour),

              },
              received_profile_bonus: res.received_profile_bonus
            }))
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
    }, 2000)
  }, [personalData])

  useEffect(() => {
    if (!user) return
    const delayDebounceFn = setTimeout(() => {
      mainApi
        .getMe()
        .then((res) => {
          if (Number(res.balance_data?.level) !== Number(user.balance_data?.level)) {
            setUser((prevValue) => ({
              ...prevValue,
              // user.balance.level
              balance_data: {
                ...prevValue.balance_data,
                level: res.balance_data.level
              }
            }))
            setOpenLevelUp(!isOpenLevelUp)
          }
          if (res.balance_data?.rating !== user?.balance_data?.rating) {
            // setUser(res)
            setRaitingNumber(res.balance_data?.rating)
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
    }, 3000000)

    return () => {
      clearTimeout(delayDebounceFn);
    }
  }, [user])

  const backButton = tg?.BackButton;

  useEffect(() => {
    if (location.pathname !== '/') {
      backButton?.show();
    } else {
      backButton?.hide();
    }
    const handleBackClick = () => {
      navigate(-1);
    };
    backButton?.onClick(handleBackClick);
    return () => {
      backButton?.offClick(handleBackClick);
    };
  }, [location.pathname]);


  // console.log({
  //   language_code: tg?.initDataUnsafe?.user?.language_code,
  //   initUser: tg?.initDataUnsafe?.user,
  //   tg: tg,
  // })

  return (
    <TranslationContext.Provider value={translations[language]}>
      {
        tg && tg.platform && (tg.platform === 'ios' || tg?.platform === 'android') ?
          // true ?
          <div className={`app ${location.pathname === '/' ? 'app_scrollable' : ''}`}>
            {preloaders.app || !user ? <Loading success={success} /> : null}
            {(location.pathname === '/friends' || location.pathname === '/profile') ?
              null :
              <Header
                rait={raitingNumber}
                setRaitingNumber={setRaitingNumber}
                isOpen={isOpenRating}
                setOpen={setOpenRating}
                user={user}
                card={card}
                setCard={setCard}
                total={total}
              />}
            <IncomePopup personalBonus={personalBonus} setPersonalBonus={setPersonalBonus} income={income} setIncome={setIncome} />
            <LearnPopup language={language} setUser={setUser} learnPopup={learnPopup} setLearnPopup={setLearnPopup} learn={learn} setLearn={setLearn} />
            <PhonePopup setCard={setCard} isOpen={phoneOpen} setOpen={setPhoneOpen} />
            <GiftPopup isOpen={isGiftOpen} setOpen={setGiftOpen} referral={user?.referral_info} />

            <Routes>
              <Route index element={
                <Main
                  user={user}
                  isOpenRewards={isOpenRewards} setOpenRewards={setOpenRewards}
                  total={total} setTotal={setTotal}
                  tabOpen={tabOpen} setTabOpen={setTabOpen}
                  personalData={personalData}
                  setEnergyCounter={setEnergyCounter} energyCounter={energyCounter}
                  clickable={clickable} setClickable={setClickable}
                  isOpen={isOpenRating} setOpen={setOpenRating}
                  preloader={preloaders.rewards} setPreloader={setPreloaders}
                  rewards={rewards} setRewards={setRewards}
                  language={language}
                />} />
              <Route path="/menu" element={<Menu user={user} isOpenRating={isOpenRating} language={language} />} />
              <Route path="/business" element={
                <Business
                  total={total}
                  setTotal={setTotal}
                  user={user}
                  language={language}
                />
              } />
              <Route path='/friends' element={<Friends user={user} referral={user?.referral_info} />}></Route>
              <Route path='/profile' element={
                <Profile
                  user={user}
                  setTabOpen={setTabOpen}
                  tabOpen={tabOpen}
                  total={total}
                  personalData={personalData}
                  setPersonalData={setPersonalData}
                  setTotal={setTotal}
                  card={card}
                  setCard={setCard}
                  personalBonus={personalBonus}
                  setPersonalBonus={setPersonalBonus}
                  language={language}
                />} />
              <Route path='/airdrop' element={<Airdrop user={user} referral={user?.referral_info} />}></Route>
            </Routes>
            <NavBlock setOpen={setOpenRating} personalData={personalData} />
            <LevelUpPopup isOpenLevelUp={isOpenLevelUp} setOpenLevelUp={setOpenLevelUp} />
            <Rating
              isOpenRating={isOpenRating}
              setOpenRating={setOpenRating}
              user={user}
              total={total}
              language={language}
            />
            <Rewards
              user={user}
              isOpenRewards={isOpenRewards}
              setOpenRewards={setOpenRewards}
              setTotal={setTotal}
              total={total}
              rewards={rewards} setRewards={setRewards}
              language={language}
            />
            <Count user={user} setTotal={setTotal} setCountVisible={setCountVisible} total={total} countVisible={countVisible} />
          </div>
          :
          <ForComputer />
      }
    </TranslationContext.Provider>

  );
}


export default App;
