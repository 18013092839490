import './Airdrop.css';
import icon from '../../assets/images/airdrop-icon.webp'
import { useContext, useEffect, useState } from 'react';
import { TranslationContext } from '../../assets/contexts/translationContext';
import { FRIENDS_LIMIT } from '../../assets/utils/constants';
import mainApi from '../../assets/api/MainApi';
import MiniPreloader from '../MiniPreloader/MiniPreloader';

function Airdrop({ user, referral }) {
    const tg = window.Telegram?.WebApp

    const {
        airdrop: {
            title,
            dateTitle,
            dateValue,
            reqTitle,
            friendsTitle,
            friendsText,
            otherTitle,
            otherValue,
            subscribeTg
        },
    } = useContext(TranslationContext);

    const [friends, setFriends] = useState(null)
    const [preloaders, setPreloaders] = useState({
        friends: true,
    })

    useEffect(() => {
        if (!user) return
        setPreloaders(prevValue => ({
            ...prevValue,
            friends: true,
        }))
        mainApi
            .getFriends({ limit: FRIENDS_LIMIT })
            .then((res) => {
                setFriends(res.data);
                // setFriends(FRIENDS_CARDS)
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    friends: false,
                }))
            })
    }, [user])


    return (
        <div className='airdrop'>
            <img className="airdrop__icon" src={icon} alt='' />
            <h3 className='airdrop__title'>{title}</h3>
            <div className='airdrop__info'>
                <div className='airdrop__info-box airdrop__info-box_main'>
                    <p className='airdrop__info-title'>{dateTitle}</p>
                    <p className='airdrop__info-value'>{dateValue}</p>
                </div>
            </div>
            <h3 className='airdrop__req-title'>{reqTitle}</h3>
            <div className='airdrop__info'>
                <div className='airdrop__info-box'>
                    <p className='airdrop__info-title'>{friendsTitle}</p>
                    {preloaders.friends ?
                        <MiniPreloader isLinkColor={true} />
                        :
                        <p className='airdrop__info-value'>{friendsText} {friends && friends.length - (referral?.parent ? 1 : 0) > 0 ? friends.length - (referral?.parent ? 1 : 0) >= FRIENDS_LIMIT ? `+${FRIENDS_LIMIT}` : friends.length - (referral?.parent ? 1 : 0) : '0'}</p>
                    }
                </div>
                <div className='airdrop__info-box'>
                    <p className='airdrop__info-title' onClick={() => {
                        tg?.openInvoice('https://t.me/$kSnkaqxjAVAwAgAAK09uPB5fS0o', (status) => {
                            if (status) {
                                // 'cancelled'
                                // 'paid'
                            } else {
                                console.log('Phone number not shared');
                            }
                        });
                    }}>{otherTitle}</p>
                    <p className='airdrop__info-value'>{otherValue}</p>
                </div>

                <a href='https://t.me/union_tap' className='airdrop__info-box airdrop__info-box_tg'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <path d="M18 0C8.05867 0 0 8.05867 0 18C0 27.9413 8.05867 36 18 36C27.9413 36 36 27.9413 36 18C36 8.05867 27.9413 0 18 0ZM26.2427 11.448C26.1413 13.1013 23.0747 25.44 23.0747 25.44C23.0747 25.44 22.8907 26.1627 22.2347 26.1867C21.9947 26.1947 21.704 26.1467 21.3573 25.848C20.664 25.2533 19.0613 24.1147 17.5707 23.0853C17.12 23.464 16.2213 24.2427 15.2907 25.1707C13.9733 26.488 14.1413 25.1707 14.1413 25.1707L14.5467 20.8693L14.5573 20.8747C14.576 20.832 14.6053 20.7947 14.6053 20.7947C14.6053 20.7947 22.368 13.8933 22.5733 13.1707C22.5893 13.1147 22.528 13.088 22.448 13.112C21.9333 13.3013 12.9947 18.9467 12.008 19.568C11.9493 19.6053 11.7893 19.5813 11.7893 19.5813L7.45333 18.168C7.45333 18.168 6.936 17.9573 7.10133 17.48C7.136 17.3813 7.20533 17.2987 7.41067 17.1547C8.37067 16.4853 25.1947 10.4373 25.1947 10.4373C25.1947 10.4373 25.6693 10.2773 25.9493 10.384C26.0773 10.432 26.16 10.488 26.2293 10.688C26.2533 10.76 26.2693 10.9173 26.2667 11.072C26.2667 11.184 26.2507 11.2853 26.2427 11.448Z" fill="white" />
                    </svg>
                    {subscribeTg}
                </a>
            </div>
        </div>
    );
}


export default Airdrop;