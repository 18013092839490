import { NavLink, useLocation } from 'react-router-dom';
import '../NavBlock/NavBlock.css';
import { motion } from 'framer-motion';
import { NAV } from '../../assets/utils/constants';
import { useContext } from 'react';
import { TranslationContext } from '../../assets/contexts/translationContext';

function NavBlock({ setOpen, personalData }) {

    const {
        navBlock: {
            links,
        },
    } = useContext(TranslationContext);

    function handleClick() {
        window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft')
        setOpen(false)
    }

    const LINkS_WITH_TRANSLATION = NAV.map((item, i)=>{
        return {
            ...item,
            ...links[i],
        }
    })
    return (
        <div className="nav-block">
            <div className='nav-block__box'>
                {LINkS_WITH_TRANSLATION.map((item, i) => {
                    return (
                        <NavLink
                            key={i}
                            onClick={() => handleClick()}
                            className={({ isActive }) => (isActive ? 'nav-block__link_active' : 'nav-block__link')}
                            to={`${item.link}`}>
                            {!personalData.first_name || !personalData.last_name || !personalData.email || !personalData.date_of_birth && item.link === '/profile' ? <div className='nav-block__red-circle' ></div> : null}
                            {item.icon}
                            <p className='nav-block__title'>{item.title}</p>
                        </NavLink>
                    )
                })}
            </div>
        </div>
    );
}

export default NavBlock;