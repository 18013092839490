import { createRef, useContext, useEffect, useRef, useState } from "react";
import "./InputGender.css";
import { TranslationContext } from "../../../../assets/contexts/translationContext";
// import { DropdownRectangle } from "../../../assets/icons/icons";

function InputGender({
  handleGender,
  initialValue
}) {

  const {
    profileInfo: {
       maleValue,
       femaleValue,
    },
} = useContext(TranslationContext);

  
  const [active, setActive] = useState(initialValue.gender !== null ? initialValue.gender : true)

  function handleClick(value) {
    setActive(value)
  }

  useEffect(() => {
    handleGender(active)
  }, [active])

  return (
    <div className="selector">
      <div className={`${active ? 'selector__btn_active' : ''} selector__btn`} onClick={() => handleClick(true)}>{maleValue}</div>
      <div className={`${!active ? 'selector__btn_active' : ''} selector__btn`} onClick={() => handleClick(false)}>{femaleValue}</div>
    </div >
  );
}

export default InputGender;
