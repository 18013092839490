import '../LevelPopup/LevelPopup.css';
import TemplatePopup from '../../TemplatePopup/TemplatePopup';
import { Coin, CoinGrey, CoinWhite } from '../../../assets/icons/icons';
import { motion } from 'framer-motion';
import noPhoto from '../../../assets/images/NoPhoto.png'
import { useContext } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { AVATARS } from '../../../assets/utils/utils';

function LevelPopup({ isOpen, setOpen, language }) {

    const {
        levelInfoPopup: {
            iconText,
            levelText,
            coinsText,
        },
    } = useContext(TranslationContext);

    const LEVEL_INFO = [
        {
            level: 1,
            balance_needed: 0,
            img: AVATARS[0]
        },
        {
            level: 2,
            balance_needed: 10000,
            img: AVATARS[1]
        },
        {
            level: 3,
            balance_needed: 100000,
            img: AVATARS[2]
        },
        {
            level: 4,
            balance_needed: 500000,
            img: AVATARS[3]
        },
        {
            level: 5,
            balance_needed: 1000000,
            img: AVATARS[4]
        },
        {
            level: 6,
            balance_needed: 10000000,
            img: AVATARS[5]
        },
        {
            level: 7,
            balance_needed: 50000000,
            img: AVATARS[6]
        },
        {
            level: 8,
            balance_needed: 100000000,
            img: AVATARS[7]
        },
    ]
    return (
        <>
            {isOpen ?
                <motion.div
                    onClick={() => setOpen(!isOpen)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className='popup__bg' />
                : null}
            <TemplatePopup secondClassName={'popup-level-info'} isOpen={isOpen} setOpen={setOpen}>
                <div className='popup-level-info__table-box'>
                    <div className='popup-level-info__table-box__header'>
                        <p className='menu-card-popup__table-box__header__title'>{iconText}</p>
                        <p className='menu-card-popup__table-box__header__title'>{levelText}</p>
                        <p className='menu-card-popup__table-box__header__title'>{coinsText}</p>
                    </div>
                    {LEVEL_INFO.map((item, i) => {
                        return (
                            <div className='popup-level-info__table'>
                                <img src={item.img} alt='' className='popup-level-info__table__img'></img>
                                <p className='popup-level-info__table__level'>{item.level}</p>
                                <p className='popup-level-info__table__balance'>
                                    {Intl.NumberFormat('en-US', {
                                        notation: "compact",
                                        maximumFractionDigits: 2
                                    }).format(Number(item.balance_needed))}
                                </p>
                            </div>
                        )
                    })}
                </div>
            </TemplatePopup>
        </>
    );
}

export default LevelPopup;