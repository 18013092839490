import '../ProfileCard/ProfileCard.css';
import noPhoto from '../../../assets/images/NoPhoto.png'

function ProfileCard({ item, setCurrent, current, setOpen, isOpen, arhiv, preloade, language }) {

    function handleClick(item) {
        if (setCurrent && current) {
            setCurrent(item)
            setOpen(!isOpen)
        }
    }

    return (
        <div onClick={() => handleClick(item)} className={`${preloade ? 'profile-card__animated-gradient' : 'profile-card'}`}>
            {preloade ? <div className='profile-card__img_animated-gradient'></div> :
                item?.coupon?.image ?
                    <img alt='' className={`${preloade ? 'profile-card__img_animated-gradient' : ''} profile-card__img ${arhiv ? 'profile-card_grey' : ''}`} src={item?.coupon?.image}></img>
                    :
                    <img alt='' src={noPhoto} className='profile-card__img'></img>
            }
            <div className={`${preloade ? 'profile-card__value_animated-gradient' : 'profile-card__value'}`}>
                {item?.coupon?.title_localized[language] ? item?.coupon?.title_localized[language] : item?.coupon?.title}
            </div>
        </div>
    );
}

export default ProfileCard;